import { Radio, RadioGroup } from '@headlessui/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Description, ErrorMessage, Field, FieldGroup, Label } from '@oysterjs/uiv2/fieldset';
import { Input } from '@oysterjs/uiv2/input';
import { Select } from '@oysterjs/uiv2/select';
import { Textarea } from '@oysterjs/uiv2/textarea';
import { Checkbox, CheckboxGroup } from '@oysterjs/uiv2/checkbox';
import { CheckboxField as __CheckboxField } from '@oysterjs/uiv2/checkbox';
import { AddressAutofill } from '@mapbox/search-js-react';
import { MAPBOX_PUBLIC_KEY } from '@oysterjs/core/mapbox';
import { Address } from '../../types/graphql';
import { states } from './naics';

type BaseFieldProps = {
  label: string;
  description?: string;
  value?: string;
  error?: string;
  onChange: (value: string) => void;
};

export const DateField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="date"
        className="xs:w-36"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const EmailField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="email"
        className="sm:w-1/2"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const PhoneNumberField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="tel"
        className="sm:w-1/2"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const NumberField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="number"
        className="sm:w-24"
        value={props.value}
        invalid={!!props.error}
        onKeyDown={(e) =>
          e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
        }
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const YearField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="number"
        className="sm:w-24"
        value={props.value}
        invalid={!!props.error}
        onKeyDown={(e) =>
          e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
        }
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const TextField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Textarea
        resizable={false}
        rows={3}
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type SelectFieldProps = {
  options: { displayText: string; value: string }[];
};

export const SelectField = (props: BaseFieldProps & SelectFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Select
        className="sm:w-1/2"
        value={props.value || ''}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      >
        <option value=""></option>
        {props.options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.displayText}
          </option>
        ))}
      </Select>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type RadioFieldProps = {
  options: { displayText: string; value: string }[];
};

export const RadioField = (props: BaseFieldProps & RadioFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <RadioGroup data-slot="control" value={props.value} onChange={props.onChange}>
        {props.options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            className="inline-block cursor-pointer focus:outline-none items-center justify-center rounded-md bg-white dark:bg-neutral-800 px-8 py-4 sm:px-6 sm:py-3 mr-2 sm:text-sm font-medium text-neutral-900 dark:text-white ring-1 ring-neutral-300 dark:ring-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-700 data-[checked]:bg-primary-500 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-primary-500 data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
          >
            {option.displayText}
          </Radio>
        ))}
      </RadioGroup>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type MultiSelectFieldProps = {
  values: string[];
  options: { displayText: string; value: string }[];
  onChangeMulti: (values: string[]) => void;
};

export const MultiSelectField = (props: BaseFieldProps & MultiSelectFieldProps) => {
  return (
    <Field>
      {props.label && <Label>{props.label}</Label>}
      {props.description && <Description>{props.description}</Description>}
      <CheckboxGroup className="grid sm:grid-cols-3 xs:grid-cols-2">
        {props.options.map((option) => (
          <__CheckboxField key={option.value}>
            <Checkbox
              color="sky"
              value={option.value}
              checked={props.values.includes(option.value)}
              onChange={(checked) =>
                props.onChangeMulti(
                  checked
                    ? [...props.values, option.value]
                    : props.values.filter((v) => v !== option.value)
                )
              }
            />
            <Label>{option.displayText}</Label>
          </__CheckboxField>
        ))}
      </CheckboxGroup>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const CheckboxField = MultiSelectField;

export const AddressForm = (props: {
  onChange: (update: (prev: Address) => Address) => void;
  disabled?: boolean;
  addressTitle: string;
  addressDescription?: string;
  initialValue: Address;
  validationErrors?: {
    line1?: string;
    line2?: string;
    city?: string;
    zone?: string;
    postalCode?: string;
  };
}) => {
  const onRetrieve = (res) => {
    const address = res?.features?.[0]?.properties;
    if (address) {
      props.onChange(() => ({
        line1: address.address_line1,
        line2: address.address_line2 || undefined,
        city: address.address_level2,
        zone: address.address_level1,
        postalCode: address.postcode
      }));
    }
  };

  const showSecondLine = !!props.initialValue.line2 || !!props.validationErrors?.line2;
  const showCityStateZip =
    !!props.initialValue.city ||
    !!props.initialValue.zone ||
    !!props.initialValue.postalCode ||
    !!props.validationErrors?.city ||
    !!props.validationErrors?.zone ||
    !!props.validationErrors?.postalCode;

  return (
    <>
      <FieldGroup>
        <Field>
          <Label>{props.addressTitle}</Label>
          {props.addressDescription && <Description>{props.addressDescription}</Description>}
          <div data-slot="control">
            <AddressAutofill
              accessToken={MAPBOX_PUBLIC_KEY()}
              options={{ country: 'us' }}
              onRetrieve={onRetrieve}
            >
              <Input
                type="text"
                placeholder="276 Fifth Ave"
                autoComplete="address-line1"
                disabled={props.disabled}
                value={props.initialValue.line1}
                invalid={!!props.validationErrors?.line1}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  props.onChange((prev) => ({ ...prev, line1: val }));
                }}
              />
            </AddressAutofill>
          </div>
          {props.validationErrors?.line1 && (
            <ErrorMessage>{props.validationErrors?.line1}</ErrorMessage>
          )}
        </Field>
      </FieldGroup>
      <TransitionGroup mode="out-in">
        {showSecondLine && (
          <CSSTransition timeout={100} classNames="fade">
            <FieldGroup className="pt-6">
              <Field>
                <Input
                  type="text"
                  placeholder="Suite 100"
                  autoComplete="address-line2"
                  disabled={props.disabled}
                  value={props.initialValue.line2 || ''}
                  invalid={!!props.validationErrors?.line2}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, line2: val }));
                  }}
                />
                {props.validationErrors?.line2 && (
                  <ErrorMessage>{props.validationErrors?.line2}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </CSSTransition>
        )}
        {showCityStateZip && (
          <CSSTransition timeout={100} classNames="fade">
            <FieldGroup className="flex flex-col gap-x-4 xs:flex-row">
              <Field className="w-full xs:w-[45%] pt-6">
                <Label>City</Label>
                <Input
                  type="text"
                  placeholder="New York"
                  autoComplete="address-level2"
                  disabled={props.disabled}
                  value={props.initialValue.city}
                  invalid={!!props.validationErrors?.city}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, city: val }));
                  }}
                />
                {props.validationErrors?.city && (
                  <ErrorMessage>{props.validationErrors?.city}</ErrorMessage>
                )}
              </Field>
              <Field className="w-full xs:w-[35%]">
                <Label>State</Label>
                <Select
                  autoComplete="address-level1"
                  disabled={props.disabled}
                  value={props.initialValue.zone}
                  invalid={!!props.validationErrors?.zone}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, zone: val }));
                  }}
                >
                  <option value="" disabled></option>
                  {states.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.name}
                    </option>
                  ))}
                </Select>
                {props.validationErrors?.zone && (
                  <ErrorMessage>{props.validationErrors?.zone}</ErrorMessage>
                )}
              </Field>
              <Field className="w-1/2 xs:w-[20%]">
                <Label>Zip Code</Label>
                <Input
                  type="text"
                  placeholder="10001"
                  autoComplete="postal-code"
                  disabled={props.disabled}
                  value={props.initialValue.postalCode}
                  invalid={!!props.validationErrors?.postalCode}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, postalCode: val }));
                  }}
                />
                {props.validationErrors?.postalCode && (
                  <ErrorMessage>{props.validationErrors?.postalCode}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};
