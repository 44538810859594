import * as React from 'react';
import styled from 'styled-components';
import { IoArrowForward, IoLockClosed } from 'react-icons/io5';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import { useAuth } from '@oysterjs/core/auth';
import { OysterLogoOld } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { DigitsInput } from '@oysterjs/ui/Form/digits';
import { TextInput } from '@oysterjs/ui/Form/text';
import { AdditionalMerchantProductType, ProductType, ValidationError } from '@oysterjs/types';
import { FormColumn, FormContainer, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { FormRow } from '@oysterjs/ui/Form/builder';
import {
  createMerchant,
  merchantUserSignInComplete,
  merchantUserSignInInit
} from '@oysterjs/core/api/merchant';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { Select } from '@oysterjs/ui/Form/select';

const SignInContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0px;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

const SignInPane = styled.div`
  width: 50%;
  max-width: 700px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const SignInImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 320px;
  max-height: 400px;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

const SignInPage = (props: { redirect?: string }) => {
  const history = useHistory();

  const [email, setEmail] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const redirectUri = props.redirect ? `redirect=${encodeURIComponent(props.redirect)}` : '';
  const signupUri = `/signup?${redirectUri}`;

  const handleLogin = () => {
    setLoading(true);
    setTimeout(
      () =>
        merchantUserSignInInit(email)
          .then(() =>
            history.push(
              `/signin?email=${encodeURIComponent(email)}` +
                `${redirectUri ? `&${redirectUri}` : ''}`
            )
          )
          .catch((err) => setError(err.message))
          .finally(() => setLoading(false)),
      500
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <PageSection>
      <SignInContainer>
        <SignInPane>
          <h1 style={{ marginBottom: '0' }}>Merchant Sign In</h1>
          <p
            style={{
              marginTop: '6px',
              marginBottom: '24px',
              fontSize: '0.9em',
              color: '#666666'
            }}
          >
            Don't have a merchant account yet?{' '}
            <LinkComponent href={signupUri}>Create one</LinkComponent>.
          </p>
          <FormContainer onSubmit={onSubmit} autoComplete="on">
            <FormRow>
              <FormColumn title="Email Address">
                <TextInput
                  type="email"
                  name="email"
                  inputMode="email"
                  autoComplete="email"
                  value={email}
                  error={error}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <Button
                primary
                leftIcon={<IoLockClosed />}
                icon={<IoArrowForward />}
                loading={loading}
                onClick={() => handleLogin()}
              >
                Sign In
              </Button>
            </FormRow>
          </FormContainer>
        </SignInPane>

        <SignInImage src="/images/signin.svg" />
      </SignInContainer>
    </PageSection>
  );
};

const SignUpPage = (props: { code?: string; redirect?: string }) => {
  const history = useHistory();

  const [formData, setFormData] = React.useState({
    BusinessName: '',
    BusinessDBA: '',
    BusinessDomain: '',
    ProductVerticals: [],
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    AccessCode: props.code || ''
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [validationError, setValidationError] = React.useState<ValidationError>();

  const redirectUri = props.redirect ? `redirect=${encodeURIComponent(props.redirect)}` : '';
  const signinUri = `/signin?${redirectUri}`;

  const setData = (field: string, value: string | ProductType[]) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSignUp = () => {
    setLoading(true);
    createMerchant({
      Merchant: {
        BusinessProfile: {
          Name: formData.BusinessName,
          DBA: formData.BusinessDBA,
          Email: formData.Email,
          Phone: formData.Phone,
          Domain: formData.BusinessDomain,
          ProductVerticals: formData.ProductVerticals
        }
      },
      MerchantUser: {
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        Email: formData.Email,
        Phone: formData.Phone
      },
      AccessCode: formData.AccessCode
    })
      .then(() =>
        history.push(
          `/signin?email=${encodeURIComponent(formData.Email)}` +
            `${redirectUri ? `&${redirectUri}` : ''}`
        )
      )
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() === ErrorType.validationError) {
          setError('');
          setValidationError(err.getValidationError());
        } else {
          setValidationError(undefined);
          setError(err.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSignUp();
  };

  return (
    <PageSection>
      <h1 style={{ marginBottom: '0px' }}>Get started</h1>
      <p
        style={{
          marginTop: '4px',
          marginBottom: '24px',
          color: '#333333'
        }}
      >
        Tell us some basic information about your business and yourself. Already have a merchant
        account? <LinkComponent href={signinUri}>Sign in</LinkComponent>.
      </p>
      <FormContainer onSubmit={onSubmit}>
        <FormRowHeader title="About your business" />
        <FormRow>
          <FormColumn
            title="Business Name"
            description="Enter the legal name of your business as it is registered with the state."
          >
            <TextInput
              type="text"
              name="business-name"
              error={validationError?.SubField === 'Name' && validationError?.Message}
              value={formData.BusinessName}
              onChange={(e) => setData('BusinessName', e.currentTarget.value)}
              autoComplete="business-name"
            />
          </FormColumn>
          <FormColumn
            title="Business DBA"
            description="Enter the DBA of your business, or the name you would like to display to customers."
          >
            <TextInput
              type="text"
              name="business-dba"
              error={validationError?.SubField === 'DBA' && validationError?.Message}
              value={formData.BusinessDBA}
              onChange={(e) => setData('BusinessDBA', e.currentTarget.value)}
              autoComplete="business-name"
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn
            title="Business Website"
            description="Enter the URL of your business website."
          >
            <TextInput
              type="text"
              name="domain"
              error={validationError?.SubField === 'Domain' && validationError?.Message}
              value={formData.BusinessDomain}
              onChange={(e) => setData('BusinessDomain', e.currentTarget.value)}
              autoComplete="website"
            />
          </FormColumn>
          <FormColumn
            title="Business Type"
            description="Choose the type of products your business sells."
          >
            <Select
              options={[
                { value: '' },
                { displayValue: 'Bikes & eBikes', value: ProductType.bike },
                { displayValue: 'Jewelry', value: ProductType.jewelry },
                { displayValue: 'Electronics', value: ProductType.electronics },
                {
                  displayValue: 'Collectibles',
                  value: AdditionalMerchantProductType.collectibles
                },
                {
                  displayValue: 'Home and Furniture',
                  value: AdditionalMerchantProductType.furniture
                },
                { displayValue: 'Fashion', value: AdditionalMerchantProductType.fashion },
                { displayValue: 'Motorcycle', value: ProductType.motorcycle },
                {
                  displayValue: 'Off-road Vehicles',
                  value: ProductType.offroad
                },
                {
                  displayValue: 'Food and Beverage',
                  value: AdditionalMerchantProductType.food_and_beverage
                },
                {
                  displayValue: 'Beauty and Supplements',
                  value: AdditionalMerchantProductType.beauty_and_supplements
                },
                {
                  displayValue: 'Outdoor Sports',
                  value: AdditionalMerchantProductType.outdoor_sports
                },
                { displayValue: 'Other', value: AdditionalMerchantProductType.other }
              ]}
              value={formData.ProductVerticals[0] || ''}
              onChange={(value) => {
                setData('ProductVerticals', value ? [value] : []);
              }}
              error={validationError?.SubField === 'ProductVerticals' && validationError?.Message}
            />
          </FormColumn>
        </FormRow>
        <FormRowHeader title="About you" />
        <FormRow>
          <FormColumn title="First Name" description="Your preferred first name.">
            <TextInput
              type="text"
              name="first-name"
              error={validationError?.Field === 'FirstName' && validationError?.Message}
              value={formData.FirstName}
              onChange={(e) => setData('FirstName', e.currentTarget.value)}
              autoComplete="first-name"
            />
          </FormColumn>
          <FormColumn title="Last Name" description="Your preferred last name.">
            <TextInput
              type="text"
              name="last-name"
              error={validationError?.Field === 'LastName' && validationError?.Message}
              value={formData.LastName}
              onChange={(e) => setData('LastName', e.currentTarget.value)}
              autoComplete="last-name"
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn
            title="Email Address"
            description="Your email address. You'll use this to log in."
          >
            <TextInput
              type="email"
              name="email"
              error={
                (validationError?.Field === 'Email' || validationError?.SubField === 'Email') &&
                validationError?.Message
              }
              value={formData.Email}
              onChange={(e) => setData('Email', e.currentTarget.value)}
              inputMode="email"
              autoComplete="email"
            />
          </FormColumn>
          <FormColumn title="Phone Number" description="Your phone number, for support purposes.">
            <TextInput
              type="tel"
              name="tel"
              error={
                (validationError?.Field === 'Phone' || validationError?.SubField === 'Phone') &&
                validationError?.Message
              }
              value={formData.Phone}
              onChange={(e) => setData('Phone', e.currentTarget.value)}
              inputMode="tel"
              autoComplete="tel"
            />
          </FormColumn>
        </FormRow>
        <ButtonContainer style={{ paddingTop: '20px' }}>
          <Button
            primary
            leftIcon={<IoLockClosed />}
            icon={<IoArrowForward />}
            loading={loading}
            onClick={() => handleSignUp()}
          >
            Sign Up
          </Button>
        </ButtonContainer>
        {error && <ErrorDisplay>{error}</ErrorDisplay>}
        <p style={{ color: '#666666', fontSize: '0.8em', marginBottom: '0px' }}>
          By signing up, you agree to Oyster's{' '}
          <a target="_blank" href="https://www.withoyster.com/terms-conditions">
            Terms of Service
          </a>
          {', '}
          <a target="_blank" href="https://www.withoyster.com/privacy-policy">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a target="_blank" href="http://www.withoyster.com/merchant-partner-agreement">
            Merchant Partner Agreement
          </a>
          .
        </p>
      </FormContainer>
    </PageSection>
  );
};

const SignInCompletePage = (props: { email: string; redirect?: string; code?: string }) => {
  const history = useHistory();

  const [code, setCode] = React.useState(props.code);
  const [loading, setLoading] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [error, setError] = React.useState('');
  const [, setAuth] = useAuth();

  const resendCode = () => {
    setResending(true);
    setTimeout(
      () =>
        merchantUserSignInInit(props.email, props.redirect)
          .catch((err) => setError(err.message))
          .finally(() => setResending(false)),
      500
    );
  };

  const handleLogin = (code?: string) => {
    if (code === undefined || code.length !== 6) {
      return;
    }

    setLoading(true);
    setTimeout(
      () =>
        merchantUserSignInComplete(props.email, code)
          .then((res) => {
            setLoading(false);
            setAuth(res);
            props.redirect ? history.replace(props.redirect) : history.push('/');
          })
          .catch((err) => {
            setLoading(false);
            setError(err.message);
          }),
      500
    );
  };

  React.useEffect(() => {
    handleLogin(props.code);
  }, [props.code]);

  return (
    <>
      <div style={{ height: '40px' }} />
      <PageSection noBorder>
        <h1>Check your email</h1>
        <p>
          We've sent a magic link to <b>{props.email}</b>. Click the link in the email to finish
          signing in, or enter the 6-digit code from the email here. The code is valid for 15
          minutes.
        </p>
      </PageSection>
      <FormContainer>
        <PageSection noBorder>
          <DigitsInput
            digits={6}
            initialDigits={props.code}
            disabled={loading}
            onInputChange={setCode}
            onComplete={handleLogin}
          />
        </PageSection>
        <PageSection>
          <ButtonContainer>
            <Button
              primary
              leftIcon={<IoLockClosed />}
              icon={<IoArrowForward />}
              onClick={(e) => {
                e.preventDefault();
                handleLogin(code);
              }}
              loading={loading}
            >
              Continue
            </Button>
            <Button loading={resending} onClick={resendCode}>
              Resend Email
            </Button>
          </ButtonContainer>
          {error && <ErrorDisplay style={{ marginTop: '12px' }}>{error}</ErrorDisplay>}
          <div style={{ height: '40px' }} />
        </PageSection>
      </FormContainer>
    </>
  );
};

const SignInCompleteWrapper: React.FunctionComponent<{
  width?: number;
  children: (data: { code?: string; email?: string; redirect?: string }) => JSX.Element | null;
}> = (props) => {
  // Triggers a re-render when the URL changes, but does not contain the query string
  useRouteMatch();

  const params = new URLSearchParams(window.location.search);
  const email = params.get('email') || undefined;
  const redirect = params.get('redirect') || undefined;
  const code = params.get('code') || undefined;

  return (
    <PageContainer width={props.width || 800}>
      <PageSection centered>
        <OysterLogoOld scale={1.5} inline />
      </PageSection>
      <ErrorBoundary>{props.children({ code, email, redirect })}</ErrorBoundary>
    </PageContainer>
  );
};

export const SignIn = (): JSX.Element => (
  <SignInCompleteWrapper>
    {({ code, email, redirect }) => {
      if (email) {
        return <SignInCompletePage email={email} redirect={redirect} code={code} />;
      }

      return <SignInPage redirect={redirect} />;
    }}
  </SignInCompleteWrapper>
);

export const SignUp = (): JSX.Element => (
  <SignInCompleteWrapper width={700}>
    {({ code, email, redirect }) => {
      if (email) {
        return <SignInCompletePage email={email} redirect={redirect} code={code} />;
      }

      return <SignUpPage redirect={redirect} code={code} />;
    }}
  </SignInCompleteWrapper>
);
