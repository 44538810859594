import clsx from 'clsx';

const colors = {
  red: 'border-t-red-500',
  orange: 'border-t-orange-500',
  amber: 'border-t-amber-400',
  yellow: 'border-t-yellow-400',
  lime: 'border-t-lime-400',
  green: 'border-t-green-500',
  emerald: 'border-t-emerald-500',
  teal: 'border-t-teal-500',
  cyan: 'border-t-cyan-400',
  sky: 'border-t-sky-500',
  blue: 'border-t-blue-500',
  indigo: 'border-t-indigo-500',
  violet: 'border-t-violet-500',
  purple: 'border-t-purple-500',
  fuchsia: 'border-t-fuchsia-400',
  pink: 'border-t-pink-400',
  rose: 'border-t-rose-400',
  zinc: 'border-t-zinc-600',
  neutral: 'border-t-neutral-500',
  white: 'border-t-white'
};

export const Spinner = ({
  color,
  size
}: {
  color?: keyof typeof colors;
  size?:
    | 'size-6'
    | 'size-8'
    | 'size-10'
    | 'size-12'
    | 'size-14'
    | 'size-16'
    | 'size-20'
    | 'size-24';
}) => (
  <div className="inline-flex items-center justify-center">
    <div
      className={clsx(
        'rounded-full border-t-2 border-r-2 border-r-transparent animate-spinner',
        colors[color || 'white'],
        size || 'size-6'
      )}
    />
  </div>
);
