import * as React from 'react';
import styled from 'styled-components';
import { useGetCertificateLazyQuery } from '../../types/graphql';
import { OysterWordMark } from '@oysterjs/ui/Logo';
import { Certificate } from './certificate';
import { CertificateSkeleton } from './skeleton';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Heading } from '@oysterjs/uiv2/heading';
import { ErrorComponentV2 } from '@oysterjs/ui/ErrorBoundary';

const TransitionContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: 0.15s opacity ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: 0.15s opacity ease;
  }
`;

export const CertificateApp = (props: { certificateNumber: string }) => {
  const [getCertificate, { data, error }] = useGetCertificateLazyQuery({
    variables: { certificateNumber: props.certificateNumber }
  });

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // For visual purposes load longer
    setTimeout(() => getCertificate().finally(() => setLoading(false)), 1500);
  }, []);

  return (
    <div className="w-full min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <div className="mx-auto max-w-7xl px-8 pb-24 sm:px-12">
        <div className="mx-auto max-w-3xl">
          <div className="pt-24 pb-16 flex flex-col gap-8">
            <OysterWordMark />
          </div>

          <SwitchTransition mode="out-in">
            <CSSTransition key={(!!error).toString()} timeout={100} classNames="fade">
              <TransitionContainer>
                {!error && (
                  <>
                    {data?.certificate?.details?.insured?.name && (
                      <Heading level={1} className="text-base/4 font-semibold text-primary-500">
                        {data.certificate.details.insured.name}
                      </Heading>
                    )}
                    {!data?.certificate?.details?.insured?.name && (
                      <div className="h-4 py-1.5 mt-4 mb-2 w-12 sm:w-48 bg-primary-200 rounded-lg dark:bg-primary-800 animate-pulse" />
                    )}
                    <Heading level={2} className="text-3xl/8 dark:text-white">
                      Certificate of Insurance
                    </Heading>
                  </>
                )}
                {error && <ErrorComponentV2 error={error} />}
              </TransitionContainer>
            </CSSTransition>
          </SwitchTransition>

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={loading.toString() + (!!error).toString()}
              timeout={100}
              classNames="fade"
            >
              <TransitionContainer>
                {loading && !error && <CertificateSkeleton />}
                {!loading && !error && data?.certificate?.details && data?.certificate?.url && (
                  <Certificate url={data.certificate.url} details={data.certificate.details} />
                )}
              </TransitionContainer>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
