import * as React from 'react';
import { Merchant, MerchantUser, ValidationError } from '@oysterjs/types';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { Button } from '@oysterjs/uiv2/button';
import { createMerchantUser, getMerchantUsers } from '@oysterjs/core/api/merchant';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle
} from '@oysterjs/uiv2/dialog';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label
} from '@oysterjs/uiv2/fieldset';
import { Input } from '@oysterjs/uiv2/input';

const UserList = (props: { users: MerchantUser[] }) => (
  <ul role="list" className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3 my-8">
    {props.users.map((user) => (
      <li key={user.Email} className="col-span-1 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-3 p-6">
          <div className="flex-1 truncate">
            <h3 className="text-sm mt-0 font-medium text-neutral-900">
              {user.FirstName} {user.LastName}
            </h3>
            <p className="mt-1 truncate text-sm text-neutral-500">{user.Email}</p>
          </div>
          <div className="rounded-full p-3 text-center bg-primary-100 font-medium text-sm">
            {user.FirstName.charAt(0)}
            {user.LastName.charAt(0)}
          </div>
        </div>
      </li>
    ))}
  </ul>
);

const AddUserForm = (props: {
  open: boolean;
  onAddUser: (users: MerchantUser[]) => void;
  onClose: () => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [formData, setFormData] = React.useState({
    FirstName: '',
    LastName: '',
    Email: ''
  });
  const [error, setError] = React.useState('');

  const handleAddUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createMerchantUser(formData);
      props.onAddUser((await getMerchantUsers()).Users);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      if (err.type() === ErrorType.validationError) {
        setError('');
        setValidationError(err.getValidationError());
      } else {
        setValidationError(undefined);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Add Team Member</DialogTitle>
      <DialogDescription>Add the specified user to the account.</DialogDescription>
      <form onSubmit={handleAddUser}>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>First Name</Label>
                <Description>The user's preferred first name.</Description>
                <Input
                  invalid={validationError?.Field === 'FirstName'}
                  placeholder="Billy"
                  value={formData.FirstName}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    setFormData((prev) => ({ ...prev, FirstName: value }));
                  }}
                />
                {validationError?.Field === 'FirstName' && (
                  <ErrorMessage>{validationError.Message}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Last Name</Label>
                <Description>The user's preferred last name.</Description>
                <Input
                  invalid={validationError?.Field === 'LastName'}
                  placeholder="May"
                  value={formData.LastName}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    setFormData((prev) => ({ ...prev, LastName: value }));
                  }}
                />
                {validationError?.Field === 'LastName' && (
                  <ErrorMessage>{validationError.Message}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Email Address</Label>
                <Description>The email address the user will use to log in.</Description>
                <Input
                  invalid={validationError?.Field === 'Email'}
                  type="email"
                  placeholder="billy@acme.com"
                  value={formData.Email}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    setFormData((prev) => ({ ...prev, Email: value }));
                  }}
                />
                {validationError?.Field === 'Email' && (
                  <ErrorMessage>{validationError.Message}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button type="button" color="white" loading={loading} onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button type="submit" color="sky" loading={loading}>
            Add User
          </Button>
        </DialogActions>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </form>
    </Dialog>
  );
};

export const TeamPage = (props: { merchant: Merchant; merchantUsers: MerchantUser[] }) => {
  const [users, setUsers] = React.useState(props.merchantUsers);
  const [showAddUser, setShowAddUser] = React.useState(false);

  return (
    <>
      <PageTitle title="Team" description="Grant other team members access to this account." />
      <UserList users={[...users]} />
      <Button type="button" color="sky" onClick={() => setShowAddUser(true)}>
        Add Team Member
      </Button>
      <AddUserForm
        open={showAddUser}
        onClose={() => setShowAddUser(false)}
        onAddUser={(users) => {
          setUsers(users);
          setShowAddUser(false);
        }}
      />
    </>
  );
};
