import React from 'react';
import {
  Combobox as HeadlessCombobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ErrorMessage } from './fieldset';
import { Badge } from './badge';

type Option<T> = { value: T; label: string; description?: string; badgeText?: string };

export function Combobox<
  T extends {
    toString(): string;
  }
>(props: {
  value?: T;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  options: Option<T>[];
  minQueryLength?: number;
  maxOptions?: number;
  onChange: (value: T | '') => void;
}) {
  const [query, setQuery] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState(
    props.value ? (props.options.find((opt) => opt.value === props.value) ?? null) : null
  );

  const queryRegex = new RegExp(`(${query.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');

  const minQueryLength = props.minQueryLength || 3;
  const maxOptions = props.maxOptions || 50;

  React.useEffect(() => {
    setSelectedOption(
      props.value ? (props.options.find((opt) => opt.value === props.value) ?? null) : null
    );
  }, [props.value, props.options]);

  const filteredOptions = props.options.filter((option) => {
    return (
      option.label.toString().toLowerCase().includes(query.trim().toLowerCase()) ||
      option.value.toString().toLowerCase().includes(query.trim().toLowerCase()) ||
      option.description?.toString().toLowerCase().includes(query.trim().toLowerCase())
    );
  });

  return (
    <>
      <HeadlessCombobox
        as="div"
        data-slot="control"
        value={selectedOption}
        onChange={(option: Option<T>) => {
          setQuery('');
          setSelectedOption(option);
          props.onChange(option?.value ?? '');
        }}
        data-invalid={props.error ? true : undefined}
        disabled={props.disabled}
        immediate={props.minQueryLength === 0}
      >
        <span
          className={clsx([
            // Basic layout
            'relative block w-full',
            // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
            'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
            // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
            'dark:before:hidden',
            // Focus ring
            'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent after:focus-within:ring-2 after:focus-within:ring-primary-500',
            // Disabled state
            'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
            // Invalid state
            'before:has-[[data-invalid]]:shadow-red-500/10'
          ])}
          data-invalid={props.error ? true : undefined}
        >
          <ComboboxInput
            className={clsx([
              // Basic layout
              'relative block w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
              // Typography
              'text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white',
              // Border
              'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
              // Background color
              'bg-transparent dark:bg-white/5',
              // Hide default focus styles
              'focus:outline-none focus:ring-0',
              // Invalid state
              'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500',
              // Disabled state
              'data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]',
              // System icons
              'dark:[color-scheme:dark]'
            ])}
            onChange={(event) => {
              setQuery(event.target.value);
              setSelectedOption(null);
              props.onChange('');
            }}
            onBlur={() => setQuery('')}
            displayValue={(option: { label: string }) => option?.label}
            data-invalid={props.error ? true : undefined}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-neutral-400" aria-hidden="true" />
          </ComboboxButton>

          {(filteredOptions.length <= maxOptions || query.trim().length >= minQueryLength) &&
            filteredOptions.length > 0 && (
              <ComboboxOptions className="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredOptions.map((option) => (
                  <ComboboxOption
                    key={option.value.toString()}
                    value={option}
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-neutral-900 data-[focus]:bg-primary-500 data-[focus]:text-white"
                  >
                    <div>
                      <div className="flex justify-between">
                        <div className="group-data-[selected]:font-semibold">
                          {option.label.split(queryRegex).map((part, i) =>
                            part.toLowerCase() === query.trim().toLowerCase() ? (
                              <span
                                key={part + i.toString()}
                                className="text-neutral-600 bg-yellow-300 font-semibold"
                              >
                                {part}
                              </span>
                            ) : (
                              <span key={part + i.toString()}>{part}</span>
                            )
                          )}
                        </div>
                        {option.badgeText && (
                          <div className="pr-2">
                            <Badge color="neutral">{option.badgeText}</Badge>
                          </div>
                        )}
                      </div>
                      {option.description && (
                        <div className="text-xs text-neutral-500 group:hover:text-neutral-100 group-data-[focus]:text-neutral-100 group:hover:group-data-[selected]:text-neutral-100">
                          {option.description.split(queryRegex).map((part, i) =>
                            part.toLowerCase() === query.trim().toLowerCase() ? (
                              <span
                                key={part + i.toString()}
                                className="text-neutral-600 bg-yellow-300 font-semibold"
                              >
                                {part}
                              </span>
                            ) : (
                              <span key={part + i.toString()}>{part}</span>
                            )
                          )}
                        </div>
                      )}
                    </div>

                    <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-primary-600 group-data-[selected]:flex group-data-[focus]:text-white">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}
        </span>
      </HeadlessCombobox>
      {!!props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </>
  );
}
