import * as React from 'react';
import clsx from 'clsx';

import {
  Merchant,
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantIntegrationType,
  MerchantRentalConfiguration,
  RentalPolicyState
} from '@oysterjs/types';
import { useHistory, useLocation } from 'react-router';
import { ShopifySetup } from '../../setups/shopify';
import { ReferralLinkSetup } from '../../setups/referralLink';
import { QRCodeSetup } from '../../setups/qrCode';
import { Slideout } from '@oysterjs/uiv2/slideout';
import { LightspeedRetailRSeriesSetup } from '../../setups/lightspeedRetailRSeries';
import { EmbeddablePageSetup } from '../../setups/embeddedMarketingPage';
import { WooCommerceSetup } from '../../setups/woocommerce';
import { CheckfrontSetup } from '../../setups/checkfront';
import {
  BookmarkIcon,
  BuildingStorefrontIcon,
  LinkIcon,
  QrCodeIcon
} from '@heroicons/react/20/solid';
import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import { ErrorBoundaryV2 } from '@oysterjs/ui/ErrorBoundary';
import { MerchantRentalSetup } from '../../setups/rental';

const productInsuranceChannels: MerchantIntegrationType[] = [
  MerchantIntegrationType.referral_link,
  MerchantIntegrationType.qr_code,
  MerchantIntegrationType.embeddable_marketing_page,
  MerchantIntegrationType.shopify,
  MerchantIntegrationType.lsretail_rseries,
  MerchantIntegrationType.woocommerce
];

const rentalInsuranceChannels: MerchantIntegrationType[] = [MerchantIntegrationType.checkfront];

const getChannelLabel = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return 'Shopify';
    case MerchantIntegrationType.lsretail_rseries:
      return 'Lightspeed';
    case MerchantIntegrationType.woocommerce:
      return 'WooCommerce';
    case MerchantIntegrationType.checkfront:
      return 'Checkfront';
    case MerchantIntegrationType.qr_code:
      return 'Marketing Kit';
    case MerchantIntegrationType.referral_link:
      return 'Referral Link';
    case MerchantIntegrationType.embeddable_marketing_page:
      return 'Marketing Page';
    case MerchantIntegrationType.custom:
      return 'Other';
    default:
      return '';
  }
};

const getChannelDescription = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return 'Configure the Oyster Shopify app to offer customers peace-of-mind.';
    case MerchantIntegrationType.lsretail_rseries:
      return 'Configure the Oyster Lightspeed app to offer customers peace-of-mind.';
    case MerchantIntegrationType.woocommerce:
      return 'Configure the Oyster WooCommerce plugin to offer customers peace-of-mind.';
    case MerchantIntegrationType.qr_code:
      return 'Print out, display, or mail physical insurance offers to your customers.';
    case MerchantIntegrationType.referral_link:
      return 'Offer customers insurance anywhere with your own landing page.';
    case MerchantIntegrationType.embeddable_marketing_page:
      return 'Embed Oyster insurance information on a page on your website.';
    case MerchantIntegrationType.checkfront:
      return 'Configure the Oyster CheckFront app to offer customers peace-of-mind.';
    case MerchantIntegrationType.custom:
      return 'I use a different online sales platform.';
    default:
      return '';
  }
};

const getChannelImage = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return <img className="h-8 w-8 flex-shrink-0" src="/images/shopify.svg" />;
    case MerchantIntegrationType.lsretail_rseries:
      return <img className="h-8 w-8 flex-shrink-0" src="/images/lightspeed.svg" />;
    case MerchantIntegrationType.woocommerce:
      return <img className="h-10 w-10 flex-shrink-0" src="/images/woocommerce.svg" />;
    case MerchantIntegrationType.checkfront:
      return <img className="h-8 w-8 flex-shrink-0" src="/images/checkfront.svg" />;
    case MerchantIntegrationType.qr_code:
      return <QrCodeIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />;
    case MerchantIntegrationType.referral_link:
      return <LinkIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />;
    case MerchantIntegrationType.embeddable_marketing_page:
      return <BookmarkIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />;
    case MerchantIntegrationType.custom:
      return <BuildingStorefrontIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />;
    default:
      return <></>;
  }
};

const getChannelPath = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return '/integrations/channels/shopify';
    case MerchantIntegrationType.lsretail_rseries:
      return '/integrations/channels/lsretail-rseries';
    case MerchantIntegrationType.woocommerce:
      return '/integrations/channels/woocommerce';
    case MerchantIntegrationType.qr_code:
      return '/integrations/channels/qrcode';
    case MerchantIntegrationType.referral_link:
      return '/integrations/channels/referral';
    case MerchantIntegrationType.embeddable_marketing_page:
      return '/integrations/channels/embeddable-page';
    case MerchantIntegrationType.checkfront:
      return '/integrations/channels/checkfront';
    default:
      return '/integrations/channels';
  }
};

const getBadgeColor = (status: MerchantIntegrationStatus): string => {
  switch (status) {
    case MerchantIntegrationStatus.active:
      return 'bg-green-50 text-green-700 ring-green-600/20';
    case MerchantIntegrationStatus.pending:
      return 'bg-amber-50 text-amber-700 ring-amber-600/20';
    case MerchantIntegrationStatus.disabled:
      return 'bg-red-50 text-red-700 ring-red-600/20';
    case MerchantIntegrationStatus.inactive:
      return 'bg-neutral-50 text-neutral-700 ring-neutral-600/20';
  }
};

const getRentalConfigurationStatus = (configuration?: MerchantRentalConfiguration) => {
  switch (configuration?.Details.State) {
    case RentalPolicyState.submitted:
    case RentalPolicyState.registered:
      return MerchantIntegrationStatus.pending;
    case RentalPolicyState.approved:
    case RentalPolicyState.approved_blanket:
      return MerchantIntegrationStatus.active;
  }
};

const ChannelBadge = (props: { status?: MerchantIntegrationStatus }) => (
  <span
    className={clsx(
      'inline-flex flex-shrink-0 rounded-full px-2.5 py-0.5 text-xs font-medium ring-1 ring-inset m-0',
      getBadgeColor(props.status || MerchantIntegrationStatus.inactive)
    )}
  >
    {props.status || 'Not added'}
  </span>
);

export const ChannelCard = (props: {
  type: MerchantIntegrationType;
  status?: MerchantIntegrationStatus;
}) => {
  const history = useHistory();
  return (
    <Card
      label={getChannelLabel(props.type)}
      status={props.status}
      description={getChannelDescription(props.type)}
      image={getChannelImage(props.type)}
      onClick={() => history.push(getChannelPath(props.type))}
    />
  );
};

const Card = (props: {
  label: string;
  status?: MerchantIntegrationStatus;
  description: string;
  image: React.JSX.Element;
  onClick?: () => void;
}) => (
  <li
    role="button"
    className="col-span-1 rounded-lg bg-white shadow transition-all duration-200 hover:shadow-lg cursor-pointer"
    onClick={props.onClick}
  >
    <div className="flex w-full justify-between space-x-6 p-6">
      <div className="flex-1">
        <div className="flex items-start gap-x-3 flex-row">
          <h3 className="text-sm my-0 font-medium text-neutral-900">{props.label}</h3>
          <ChannelBadge status={props.status} />
        </div>
        <p className="text-sm mt-1 text-neutral-500">{props.description}</p>
      </div>
      {props.image}
    </div>
  </li>
);

interface ChannelSlideoutContentProps {
  title: string;
  description: string;
  integrationStatus?: MerchantIntegrationStatus;
}

const ChannelSlideoutContent = (props: React.PropsWithChildren<ChannelSlideoutContentProps>) => (
  <>
    <div className="flex flex-row justify-between items-center">
      <h2 className="text-xl font-semibold mt-0 text-neutral-900">{props.title}</h2>
      <ChannelBadge status={props.integrationStatus} />
    </div>
    <p className="text-sm text-neutral-500">{props.description}</p>
    <div className="mt-4">{props.children}</div>
  </>
);

export const ChannelsListPage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClickRental = () => {
    if (props.rentalConfiguration?.Details.State === RentalPolicyState.approved) {
      history.push('/rental');
    }

    history.push('/integrations/channels/rental');
  };

  const integrationByType = (type: MerchantIntegrationType): MerchantIntegration | undefined => {
    return props.merchantIntegrations?.find((i) => i.Type === type);
  };

  return (
    <div className="@container">
      <div className="border-b border-neutral-200 pb-4">
        <h2 className="text-base font-medium text-neutral-900 font-body m-0">Product Insurance</h2>
        <p className="mt-1 text-sm text-neutral-500">
          Configure the channels you use and offer insurance to your customers through them.
        </p>
      </div>
      <ul role="list" className="grid grid-cols-1 gap-8 mt-8 @lg:grid-cols-2 @5xl:grid-cols-3">
        {props.merchantIntegrations.map(
          (integration) =>
            productInsuranceChannels.includes(integration.Type) && (
              <ChannelCard
                key={integration.ID}
                type={integration.Type}
                status={integration.Status}
              />
            )
        )}
        {productInsuranceChannels
          .filter((integration) => {
            return props.merchantIntegrations.every(
              (merchantIntegration) => merchantIntegration.Type != integration
            );
          })
          .map((integration) => (
            <ChannelCard key={integration} type={integration} />
          ))}
      </ul>

      <div className="mt-8 border-b border-neutral-200 pb-4">
        <h2 className="text-base font-medium text-neutral-900 font-body m-0">Rental Insurance</h2>
        <p className="mt-1 text-sm text-neutral-500">
          Configure the channels you use and offer rental waivers to your customers through them.
        </p>
      </div>
      <ul role="list" className="grid grid-cols-1 gap-8 mt-8 @lg:grid-cols-2 @5xl:grid-cols-3">
        <Card
          key="rental_insurance"
          label="Rental Insurance"
          status={getRentalConfigurationStatus(props.rentalConfiguration)}
          description="Provide theft and damage waivers to customers for rentals."
          image={<DocumentCheckIcon className="h-8 w-8 flex-shrink-0 text-neutral-700" />}
          onClick={onClickRental}
        />
        {props.merchantIntegrations.map(
          (integration) =>
            rentalInsuranceChannels.includes(integration.Type) && (
              <ChannelCard
                key={integration.ID}
                type={integration.Type}
                status={integration.Status}
              />
            )
        )}
        {rentalInsuranceChannels
          .filter((integration) => {
            return props.merchantIntegrations.every(
              (merchantIntegration) => merchantIntegration.Type != integration
            );
          })
          .map((integration) => (
            <ChannelCard key={integration} type={integration} />
          ))}
      </ul>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/woocommerce')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="WooCommerce"
          description="Offer shoppers peace of mind via the WooCommerce integration."
          integrationStatus={
            props.merchantIntegrations?.find((i) => i.Type === MerchantIntegrationType.woocommerce)
              ?.Status
          }
        >
          <WooCommerceSetup
            apiKey={props.apiKey}
            integration={integrationByType(MerchantIntegrationType.woocommerce)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/lsretail-rseries')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Lightspeed R-Series"
          description="Offer shoppers peace of mind via the Lightspeed R-Series integration."
          integrationStatus={
            props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.lsretail_rseries
            )?.Status
          }
        >
          <LightspeedRetailRSeriesSetup
            integration={integrationByType(MerchantIntegrationType.lsretail_rseries)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/checkfront')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Checkfront"
          description="Offer shoppers peace of mind via the Checkfront integration."
          integrationStatus={integrationByType(MerchantIntegrationType.checkfront)?.Status}
        >
          <CheckfrontSetup
            integration={integrationByType(MerchantIntegrationType.checkfront)}
            onUpdateIntegration={props.onUpdateIntegration}
            publicHandle={props.merchant.PublicHandle}
          />
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/shopify')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Shopify App"
          description="Offer shoppers peace of mind via the Oyster Shopify app."
          integrationStatus={integrationByType(MerchantIntegrationType.shopify)?.Status}
        >
          <ShopifySetup
            apiKey={props.apiKey}
            integration={props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.shopify
            )}
            onUpdateIntegration={props.onUpdateIntegration}
          ></ShopifySetup>
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/referral')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Referral Link"
          description="Customize a landing page for your business that customers can use to get a quote."
          integrationStatus={
            props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.referral_link
            )?.Status
          }
        >
          <ReferralLinkSetup
            integration={integrationByType(MerchantIntegrationType.referral_link)}
            onUpdateIntegration={props.onUpdateIntegration}
          ></ReferralLinkSetup>
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/qrcode')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Marketing Kit"
          description="Customize and print a kit to help offer insurance to your customers."
          integrationStatus={integrationByType(MerchantIntegrationType.qr_code)?.Status}
        >
          <QRCodeSetup
            integration={integrationByType(MerchantIntegrationType.qr_code)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View channel"
        showing={location.pathname.endsWith('/embeddable-page')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Embedded Oyster Page"
          description="Display Oyster insurance information page on your website."
          integrationStatus={
            integrationByType(MerchantIntegrationType.embeddable_marketing_page)?.Status
          }
        >
          <EmbeddablePageSetup
            apiKey={props.apiKey}
            integration={integrationByType(MerchantIntegrationType.embeddable_marketing_page)}
          />
        </ChannelSlideoutContent>
      </Slideout>
      <Slideout
        title="View app"
        showing={location.pathname.endsWith('/rental')}
        onClose={() => history.replace('/integrations/channels')}
      >
        <ChannelSlideoutContent
          title="Rental Insurance"
          description="Provide theft and damage waivers to customers for rentals"
          integrationStatus={getRentalConfigurationStatus(props.rentalConfiguration)}
        >
          <ErrorBoundaryV2>
            <MerchantRentalSetup
              merchant={props.merchant}
              configuration={props.rentalConfiguration}
              onContinueToRental={() => history.replace('/rental')}
            />
          </ErrorBoundaryV2>
        </ChannelSlideoutContent>
      </Slideout>
    </div>
  );
};
