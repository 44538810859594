import * as React from 'react';
import { Button } from '@oysterjs/uiv2/button';
import { requestQRCodePdf } from '@oysterjs/core/api/merchant';
import { MerchantIntegration, QRCodeIntegrationSettings } from '@oysterjs/types';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { ChannelSetting, ChannelSettingsWrapper } from './settings';
import { ColorPicker } from '@oysterjs/ui/Color';

export const QRCodeSetup = (props: {
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
  integration?: MerchantIntegration;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [err, setErr] = React.useState('');

  const onDownload = () => {
    setErr('');
    setLoading(true);
    requestQRCodePdf()
      .then((res) => {
        const binaryString = window.atob(res.DocumentZip);
        const binaryLen = binaryString.length;

        const arrayBuffer = new ArrayBuffer(binaryLen);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryLen; i++) {
          intArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: 'application/zip' });
        window.open(URL.createObjectURL(blob), '_self');
      })
      .catch(() => {
        setErr(
          'Seems like something went wrong, please contact partners@withoyster.com to get your marketing kit!'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {props.integration && (
        <>
          <Button color="sky" type="button" loading={loading} onClick={onDownload}>
            Download your kit
          </Button>
          {err && <ErrorDisplay>{err}</ErrorDisplay>}
          <ChannelSettingsWrapper
            integration={props.integration}
            onUpdateIntegration={props.onUpdateIntegration}
          >
            {(settings: QRCodeIntegrationSettings, _, onUpdateSettings) => (
              <ChannelSetting
                title="Primary Brand Color"
                description="Set the primary brand color to use in the marketing materials. Keep in mind the
            readability and contrast of the text."
                rightContent={
                  <ColorPicker
                    color={
                      (props.integration?.Settings as QRCodeIntegrationSettings).BrandColor ||
                      '#0EA5E9'
                    }
                    onUpdateSettings={(color: string) =>
                      onUpdateSettings({ ...settings, BrandColor: color })
                    }
                  />
                }
              />
            )}
          </ChannelSettingsWrapper>
          <h3 className="text-lg font-semibold text-neutral-900">Usage Tips</h3>
          <ul className="list-disc list-inside text-sm">
            <li>Download the print the marketing kit</li>
            <li>Display the QR code flyer in your store near checkout</li>
            <li>Insert the QR code flyer in product packaging</li>
          </ul>
        </>
      )}
    </>
  );
};
