import React from 'react';
import { requestEmbeddablePage } from '@oysterjs/core/api/merchant';
import { Button } from '@oysterjs/uiv2/button';
import { ErrorDetail, ErrorDetails } from '@oysterjs/ui/ErrorBoundary';
import { MerchantIntegration } from '@oysterjs/types';

export const EmbeddablePageSetup = (props: {
  apiKey: string;
  integration?: MerchantIntegration;
}) => {
  const [integration, setIntegration] = React.useState(props.integration);
  const [loading, setLoading] = React.useState(false);

  const onSetup = async () => {
    if (integration) {
      return;
    }

    setLoading(true);
    const empIntegration = await requestEmbeddablePage();
    setIntegration(empIntegration);
    setLoading(false);
  };

  const div = `<div id='oyster-container'></div>`;
  const script = `<script
  type='text/javascript'
  src='https://js.withoyster.com'
  defer='defer'
></script>
<script type='text/javascript' defer='defer'>
  window.addEventListener('load', function () {
    window.oyster.init({
      apiKey: '${props.apiKey}',
      integrationID: '${integration?.ID || ''}'
    });

    window.oyster.custom.marketing(
      document.getElementById('oyster-container')
    );
  });
</script>`;
  return (
    <>
      {!integration && (
        <div>
          <p className="text-base mb-4">
            In just a few minutes, add a fully-managed page to your website that offers Oyster
            insurance to your customers. You'll receive credit for any referrals that originate from
            this page.
          </p>
          <Button loading={loading} type="button" color="sky" onClick={onSetup}>
            Setup Oyster Page
          </Button>
          <div className="mt-4 p-4 border border-neutral-200 rounded-md">
            <img className="w-full" src="/images/oyster_emp.png" />
          </div>
        </div>
      )}
      {integration && (
        <>
          <p className="text-base my-4">
            First, create an empty page dedicated to Oyster in your store. On this page, add an HTML
            element to house the Oyster information.
          </p>
          <ErrorDetails errorData={div} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Html" description={div} />
          </ErrorDetails>
          <p className="text-base my-4">Then, paste this script.</p>
          <ErrorDetails errorData={script} style={{ maxWidth: '100%' }}>
            <ErrorDetail title="Javascript" description={script} />
          </ErrorDetails>
          <p className="text-base my-4">
            You're all set! The script takes care of everything else.
          </p>
        </>
      )}
    </>
  );
};
