import React from 'react';
import styled from 'styled-components';
import { Button, ButtonContainer, ButtonLink, LinkComponent } from '@oysterjs/ui/Button';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { OysterLogoOld } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  IoArrowForward,
  IoBagOutline,
  IoCheckmarkDone,
  IoCheckmarkSharp,
  IoFlag,
  IoTimerOutline
} from 'react-icons/io5';
import {
  Merchant,
  MerchantIntegrationType,
  Personalization,
  ProductType,
  ValidationError
} from '@oysterjs/types';
import { personalizeMerchant, getMerchantAccount } from '@oysterjs/core/api/merchant';
import { matchPath, Route as BrowserRoute, Switch, useHistory, useRouteMatch } from 'react-router';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { Progress } from '@oysterjs/ui/Form/progress';
import { Spinner } from '@oysterjs/ui/Spinner';
import { ThrowError } from '../../authenticated';
import {
  MerchantPersonalizationProductInsuranceForm,
  MerchantPersonalizationRentalInsuranceForm,
  PageProps
} from './forms';

interface Page {
  path: string;
  component: (props: PageProps) => JSX.Element;
  hasError: (validationError: ValidationError) => boolean;
  icon: JSX.Element;
}

const OptionContainer = styled.div<{ selected: boolean }>`
  transition: 0.15s all ease-in-out;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  flex-direction: row;

  padding: 24px;

  box-shadow: ${(props) => (props.selected ? '0 0 0 2px #0ea5e9' : 'none')};
  border: ${(props) => (props.selected ? '1px solid transparent' : '1px dashed #e2e2e2')};
  border-radius: 12px;
`;

const OptionCheckboxContainer = styled.div`
  width: 32px;
  padding-left: 2px;
  padding-top: 2px;
`;

const OptionContentContainer = styled.div`
  flex: 1 1 0;
`;

const OptionDescription = styled.div`
  color: #333333;
  font-size: 0.9em;
  margin-top: 4px;
`;

const OptionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.2em;
`;

const OptionContent = styled.ul`
  margin: 0px;
  padding: 16px 0px 5px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const InsuranceDetailItemContainer = styled.li`
  color: #666666;
  font-size: 0.9em;
  list-style: none;
  display: flex;
  flex: 1 0 30%;
  gap: 0px 8px;
`;

const InsuranceDetailItem = (props: { item: string }) => (
  <InsuranceDetailItemContainer>
    <div>
      <IoCheckmarkSharp style={{ color: '#0EA5E9' }} />
    </div>
    <div>{props.item}</div>
  </InsuranceDetailItemContainer>
);

const InsuranceOptionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InsuranceOptionCard = (props: {
  title: string;
  description: string;
  displayItems: string[];
  selected: boolean;
  onSelect: () => void;
}) => (
  <OptionContainer selected={props.selected} onClick={() => props.onSelect()}>
    <OptionCheckboxContainer>
      <Checkbox
        label={props.title}
        checked={props.selected || false}
        onChange={() => props.onSelect()}
      />
    </OptionCheckboxContainer>
    <OptionContentContainer>
      <OptionHeaderContainer>{props.title}</OptionHeaderContainer>
      <OptionDescription>{props.description}</OptionDescription>
      <OptionContent>
        {props.displayItems.map((item) => (
          <InsuranceDetailItem item={item} key={item} />
        ))}
      </OptionContent>
    </OptionContentContainer>
  </OptionContainer>
);

const MerchantPersonalizationIntro = (props: PageProps) => {
  const merchantEligibleForRentalInsurance =
    props.merchant.BusinessProfile.ProductVerticals?.includes(ProductType.bike);

  const updatePersonalization = (fn: (prev: Personalization) => Personalization) =>
    props.onUpdate({
      ...props.merchant,
      BusinessProfile: {
        ...props.merchant.BusinessProfile,
        Personalization: fn(props.merchant.BusinessProfile.Personalization)
      }
    });

  return (
    <>
      <PageSection noBorder>
        <h1>What can we help you with?</h1>
        <p>
          Oyster is a unified platform to serve all your insurance needs. Choose which products
          you're interested in so we can personalize your experience.
        </p>
      </PageSection>
      <PageSection noBorder>
        <InsuranceOptionCardContainer>
          <InsuranceOptionCard
            title="Insurance for my business"
            description="Oyster's team of insurance experts can help provide tailored business insurance covering the most important risks"
            displayItems={[
              'General Liability',
              "Business Owner's",
              'Rental Liability',
              'Commercial Auto',
              'Shipping',
              'Cyber',
              "Worker's Comp",
              'Product Liability',
              'And more'
            ]}
            selected={props.merchant.BusinessProfile.Personalization.BusinessInsuranceEnabled}
            onSelect={() =>
              updatePersonalization((prev) => ({
                ...prev,
                BusinessInsuranceEnabled: !prev.BusinessInsuranceEnabled
              }))
            }
          />
          <InsuranceOptionCard
            title="Insurance for my customers"
            description="Oyster's embedded insurance products can help your customers stay protected from theft, damage, and loss for their purchases"
            displayItems={['Bikes and eBikes', 'Jewelry', 'Electronics']}
            selected={props.merchant.BusinessProfile.Personalization.ProductInsuranceEnabled}
            onSelect={() =>
              updatePersonalization((prev) => ({
                ...prev,
                ProductInsuranceEnabled: !prev.ProductInsuranceEnabled
              }))
            }
          />
          {merchantEligibleForRentalInsurance && (
            <InsuranceOptionCard
              title="Insurance for my rentals assets"
              description="Oyster offers customizable pay-as-you-go insurance to protect your rental assets from damage and theft"
              displayItems={['Bikes and eBikes', 'Paddleboards', 'Kayaks', 'And more']}
              selected={props.merchant.BusinessProfile.Personalization.RentalInsuranceEnabled}
              onSelect={() =>
                updatePersonalization((prev) => ({
                  ...prev,
                  RentalInsuranceEnabled: !prev.RentalInsuranceEnabled
                }))
              }
            />
          )}
        </InsuranceOptionCardContainer>
      </PageSection>
      <PageSection noBorder>
        <ButtonContainer center>
          <ButtonLink target="_blank" href="https://meetings.hubspot.com/vic-yeh/dashboard-meeting">
            I need help with something else
          </ButtonLink>
          <Button primary icon={<IoArrowForward />} onClick={props.onNext} loading={props.loading}>
            Continue
          </Button>
        </ButtonContainer>
        {props.validationError && (
          <ErrorDisplay style={{ textAlign: 'center' }}>
            {props.validationError.Message}
          </ErrorDisplay>
        )}
      </PageSection>
    </>
  );
};

const MerchantPersonalizationProductInsurancePage = (props: PageProps) => {
  return (
    <>
      <PageSection noBorder>
        <h1>Your Sales Channels</h1>
        <p>Tell us a little bit about what and how you sell.</p>
        <MerchantPersonalizationProductInsuranceForm {...props} />
      </PageSection>
      <PageSection>
        <ButtonContainer center>
          <Button onClick={(e) => props.onBack?.(e)}>Back</Button>
          <Button primary icon={<IoArrowForward />} onClick={props.onNext} loading={props.loading}>
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </>
  );
};

const MerchantPersonalizationRentalInsurancePage = (props: PageProps) => {
  return (
    <>
      <PageSection noBorder>
        <h1>Your Rental Operations</h1>
        <p>
          We'll use this information to get you set up with the right integrations and products.
        </p>
        <MerchantPersonalizationRentalInsuranceForm {...props} />
      </PageSection>
      <PageSection>
        <ButtonContainer center>
          <Button onClick={(e) => props.onBack?.(e)}>Back</Button>
          <Button primary icon={<IoArrowForward />} onClick={props.onNext} loading={props.loading}>
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </>
  );
};

const MerchantPersonalizationCompletePageContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0px;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

const MerchantPersonalizationCompletePagePane = styled.div`
  max-width: 700px;
  flex: 1 0 1;

  a {
    font-weight: 500;
  }

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const MerchantPersonalizationCompletePageImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  max-width: 450px;
`;

const getChannelUrl = (type: MerchantIntegrationType): string => {
  switch (type) {
    case MerchantIntegrationType.referral_link:
      return `/integrations/channels/referral`;
    case MerchantIntegrationType.qr_code:
      return `/integrations/channels/qrcode`;
    case MerchantIntegrationType.shopify:
      return `/integrations/channels/shopify`;
    default:
      return ``;
  }
};

export const MerchantPersonalizationCompletePage = (props: PageProps) => {
  return (
    <PageSection>
      <MerchantPersonalizationCompletePageContainer>
        <MerchantPersonalizationCompletePagePane>
          <h1 style={{ marginBottom: '0' }}>Welcome to Oyster!</h1>
          <p>
            Thank you for becoming an Oyster partner. There are a few steps remaining, which you can
            also find in the partners dashboard.
          </p>
          {props.merchant.BusinessProfile.Personalization.BusinessInsuranceEnabled && (
            <>
              <h2>Business Insurance</h2>
              <p>
                Continue to the dashboard and complete your{' '}
                <a target="_blank" href="/business-insurance">
                  business insurance application
                </a>
                . Our team of insurance experts will reach out shortly to better understand your
                insurance needs.
              </p>
            </>
          )}
          {props.merchant.BusinessProfile.Personalization.ProductInsuranceEnabled && (
            <>
              <h2>Product Insurance</h2>
              <p>
                Access and share your{' '}
                <LinkComponent
                  target="_blank"
                  href={getChannelUrl(MerchantIntegrationType.referral_link)}
                >
                  custom referral link
                </LinkComponent>{' '}
                and{' '}
                <LinkComponent
                  target="_blank"
                  href={getChannelUrl(MerchantIntegrationType.qr_code)}
                >
                  marketing materials
                </LinkComponent>
                .
              </p>
              <p>
                Install and configure your{' '}
                <LinkComponent
                  target="_blank"
                  href={getChannelUrl(MerchantIntegrationType.shopify)}
                >
                  Shopify integration
                </LinkComponent>
                .
              </p>
              <p>
                Set your{' '}
                <LinkComponent target="_blank" href="/payouts">
                  payout method
                </LinkComponent>{' '}
                to receive revenue.
              </p>
            </>
          )}
          {props.merchant.BusinessProfile.Personalization.RentalInsuranceEnabled && (
            <>
              <h2>Rental Insurance</h2>
              <p>
                Continue to the dashboard and set up the{' '}
                <LinkComponent target="_blank" href="/channels/rental">
                  rental insurance app
                </LinkComponent>
                . Our team will reach out to get you up and running.
              </p>
            </>
          )}
          <ButtonContainer>
            <ButtonLink primary icon={<IoArrowForward />} href="/">
              Go to the dashboard
            </ButtonLink>
          </ButtonContainer>
        </MerchantPersonalizationCompletePagePane>
        <MerchantPersonalizationCompletePageImage src="/images/all_set.svg" />
      </MerchantPersonalizationCompletePageContainer>
    </PageSection>
  );
};

const getPages = (merchant: Merchant): Page[] => {
  const pages: Page[] = [
    {
      path: '/personalization',
      hasError: (err: ValidationError) => err.Field === 'InsuranceEnabled',
      component: MerchantPersonalizationIntro,
      icon: <IoFlag />
    }
  ];

  if (merchant.BusinessProfile.Personalization.ProductInsuranceEnabled) {
    pages.push({
      path: '/personalization/product',
      hasError: (err: ValidationError) =>
        [
          'SalesChannels',
          'OnlineSalesChannelType',
          'OnlineSalesChannelName',
          'ShopifyAdminURL',
          'InStoreSalesChannelType',
          'InStoreSalesChannelName',
          'RentalChannelName',
          'ProductAnnualRevenue'
        ].includes(err.Field),
      component: MerchantPersonalizationProductInsurancePage,
      icon: <IoBagOutline />
    });
  }

  if (merchant.BusinessProfile.Personalization.RentalInsuranceEnabled) {
    pages.push({
      path: '/personalization/rental',
      hasError: (err: ValidationError) => err.Field.startsWith('Rental'),
      component: MerchantPersonalizationRentalInsurancePage,
      icon: <IoTimerOutline />
    });
  }

  // Add the final page
  pages.push({
    path: '/personalization/complete',
    hasError: () => false,
    component: MerchantPersonalizationCompletePage,
    icon: <IoCheckmarkDone />
  });

  return pages;
};

export default () => {
  useRouteMatch();
  const history = useHistory();

  const [pages, setPages] = React.useState<Page[]>([]);
  const [error, setError] = React.useState<Error>();
  const [loading, setLoading] = React.useState(false);
  const [merchant, setMerchant] = React.useState<Merchant>();
  const [validationError, setValidationError] = React.useState<ValidationError>();

  // Handle initial load - fetch the initial merchant
  // account and determine if the merchant still needs
  // to personalize. If not, put them on the completion
  // page. If they do, and they're on the complete page,
  // return them to the first page.
  React.useEffect(() => {
    setLoading(true);
    getMerchantAccount()
      .then((data) => {
        setMerchant(data.Merchant);
        setPages(getPages(data.Merchant));
        if (data.PersonalizationError && window.location.pathname === '/personalization/complete') {
          history.replace('/personalization');
        }
        if (
          !data.PersonalizationError &&
          window.location.pathname.startsWith('/personalization') &&
          window.location.pathname !== '/personalization/complete'
        ) {
          history.replace('/personalization/complete');
          return null;
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (merchant) {
      setPages(getPages(merchant));
    }
  }, [merchant, window.location.pathname]);

  if (error) {
    return (
      <PageContainer width={1000}>
        <PageSection centered>
          <OysterLogoOld scale={1.5} inline />
        </PageSection>
        <PageSection noPadding>
          <ErrorBoundary>
            <ThrowError error={error} />
          </ErrorBoundary>
        </PageSection>
      </PageContainer>
    );
  }

  if (loading && !merchant) {
    return (
      <PageContainer width={700}>
        <PageSection centered>
          <OysterLogoOld scale={1.5} inline />
        </PageSection>
        <PageSection centered>
          <Spinner color="#333333" />
        </PageSection>
      </PageContainer>
    );
  }

  // This case should never happen, it's purely here to help the
  // TypeScript compiler a little bit :)
  if (!merchant) {
    return null;
  }

  const currentPageIndex = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  const onUpdate = async (merchant: Merchant) => {
    setMerchant(merchant);
    setValidationError(undefined);
  };

  const onBack = (e) => {
    e.preventDefault();
    if (currentPageIndex > 0) {
      history.push(pages[currentPageIndex - 1].path);
      window.scrollTo({ top: 0 });
    }
  };

  const onNext = async () => {
    if (!merchant) {
      return;
    }

    setLoading(true);
    try {
      const { Merchant, NextValidationError } = await personalizeMerchant(merchant);

      let nextPageIndex;
      const nextPages = getPages(Merchant);

      if (NextValidationError) {
        nextPageIndex = nextPages.findIndex((page) => page.hasError(NextValidationError));
        if (nextPageIndex === -1 || nextPageIndex - currentPageIndex > 1) {
          nextPageIndex = currentPageIndex + 1;
        }
        if (nextPageIndex <= currentPageIndex) {
          setValidationError(NextValidationError);
        }
      } else {
        setValidationError(undefined);
        nextPageIndex = currentPageIndex + 1;
      }

      if (Merchant) {
        setMerchant(Merchant);
      }

      setPages(() => {
        if (nextPageIndex !== currentPageIndex && nextPageIndex < nextPages.length) {
          history.push(nextPages[nextPageIndex].path);
          window.scrollTo({ top: 0 });
        }
        return nextPages;
      });
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <PageContainer width={currentPageIndex === pages.length - 1 ? 1000 : 700}>
        <PageSection centered>
          <OysterLogoOld scale={1.5} inline />
        </PageSection>
        {currentPageIndex !== 0 && currentPageIndex !== pages.length - 1 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < currentPageIndex ? (
                  <LinkComponent href={pages[i].path}>{page.icon}</LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={currentPageIndex}
            />
          </PageSection>
        )}
        <Switch>
          {pages.map((page) => (
            <Route
              exact
              key={page.path}
              path={page.path}
              render={() =>
                React.createElement(page.component, {
                  loading,
                  merchant,
                  validationError:
                    validationError && page.hasError(validationError) ? validationError : undefined,
                  onNext,
                  onBack,
                  onUpdate
                })
              }
            />
          ))}
        </Switch>
      </PageContainer>
    </ErrorBoundary>
  );
};
