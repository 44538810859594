import ReactTooltip from 'react-tooltip';
import { Button } from '@oysterjs/uiv2/button';
import { ColorPicker } from '@oysterjs/ui/Color';
import { MerchantIntegration, ReferralLinkIntegrationSettings } from '@oysterjs/types';
import styled from 'styled-components';
import { ChannelSetting, ChannelSettingsWrapper } from './settings';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
`;

const LinkDisplay = styled.div`
  border: 0;

  display: flex;
  flex-direction: row;

  padding: 10px 15px;
  border-radius: 4px;
  box-sizing: border-box;

  font-size: 0.8em;
  font-weight: 500;

  min-width: 100px;
  width: 100%;
  justify-content: start;

  color: #000000;
  background: #f2f2f2;
  border: '1px solid #DADADA';
  font-family: 'Inter', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
`;

export const CopyableLink = (props: { link: string }) => (
  <LinkContainer>
    <a
      data-tip
      data-for="copy-text"
      data-event="click"
      data-event-off="mouseout"
      style={{ flex: '1' }}
    >
      <LinkDisplay>{props.link}</LinkDisplay>
    </a>
    <ReactTooltip
      id="copy-text"
      effect="solid"
      delayHide={1000}
      afterShow={() => navigator.clipboard.writeText(props.link)}
    >
      Copied!
    </ReactTooltip>
    <a data-tip data-for="copy-button" data-event="click" data-event-off="mouseout">
      <Button type="button" color="sky" onClick={() => navigator.clipboard.writeText(props.link)}>
        Copy your link
      </Button>
    </a>
    <ReactTooltip
      id="copy-button"
      effect="solid"
      delayHide={1000}
      afterShow={() => navigator.clipboard.writeText(props.link)}
    >
      Copied!
    </ReactTooltip>
  </LinkContainer>
);

export const ReferralLinkSetup = (props: {
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
  integration?: MerchantIntegration;
}) => {
  const referralLink = (props.integration?.Settings as ReferralLinkIntegrationSettings)
    .ReferralLink;

  return (
    <>
      {props.integration && (
        <>
          <CopyableLink link={referralLink} />
          <ChannelSettingsWrapper
            integration={props.integration}
            onUpdateIntegration={props.onUpdateIntegration}
          >
            {(settings: ReferralLinkIntegrationSettings, _, onUpdateSettings) => (
              <ChannelSetting
                title="Primary Brand Color"
                description="Set the primary brand color to use in the marketing materials. Keep in mind the
            readability and contrast of the text."
                rightContent={
                  <ColorPicker
                    color={
                      (props.integration?.Settings as ReferralLinkIntegrationSettings).BrandColor ||
                      '#0f172a'
                    }
                    onUpdateSettings={(color: string) =>
                      onUpdateSettings({ ...settings, BrandColor: color })
                    }
                  />
                }
              />
            )}
          </ChannelSettingsWrapper>
          <h3 className="text-lg font-semibold text-neutral-900">Usage Tips</h3>
          <ul className="list-disc list-inside text-sm">
            <li>Add the referral link to your website</li>
            <li>Add the referral link to order confirmation emails</li>
            <li>Publish the referral link on social media accounts</li>
          </ul>
        </>
      )}
    </>
  );
};
