import * as React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { CertificateCoverage, CertificateDetails, InsuranceType } from '../../types/graphql';
import { IoCheckmarkOutline, IoCopyOutline, IoOpenOutline } from 'react-icons/io5';
import { getDisplayInsuranceType } from '../../types/map';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Button } from '@oysterjs/uiv2/button';

export const CertificateSectionContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: row;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const CertificateRightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 0px 0px 30px;
  flex: 1 0 224px;

  @media (max-width: 650px) {
    border-top: 1px solid #cccccc;
    padding: 30px 0px 0px 0px;
  }
`;

export const CertificateLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const CertificateRightSectionTitle = styled.div`
  text-transform: uppercase;
  color: #999999;
  font-weight: 600;
  font-size: 0.8em;
`;

const unescapeSafe = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const CertificateEntity = (props: { entity: string; name: string; address: string }) => (
  <div>
    <div className="uppercase text-sm text-primary-500 font-semibold">{props.entity}</div>
    {props.name && <p className="mt-1 font-medium">{unescapeSafe(props.name)}</p>}
    <p className={clsx('text-base/5', !props.name && 'mt-1')}>
      {props.address
        .trim()
        .split('\n')
        .map((l) => (
          <React.Fragment key={l}>
            {unescapeSafe(l)}
            <br />
          </React.Fragment>
        ))}
    </p>
  </div>
);

const CertificatePolicy = (props: { coverage: CertificateCoverage }) => (
  <div>
    <h1 className="my-1 text-lg font-medium">{getDisplayInsuranceType(props.coverage.type)}</h1>
    <h2 className="my-1 py-0 font-normal font-body text-sm text-neutral-500">
      {unescapeSafe(props.coverage.insurer.name)}
    </h2>
    <h2 className="my-1 py-0 font-normal font-body text-sm text-neutral-500">
      {props.coverage.policyNumber}
    </h2>
    <ul className="mt-2 p-0 list-none flex flex-col gap-1">
      <li className="flex gap-2 items-start">
        <CheckIcon className="text-sky-500 flex-[1_0_20px] mt-1 h-4 w-4" />
        <div className="flex-[1_0_100%]">
          Policy is effective from{' '}
          {new Date(props.coverage.effectiveDate?.toString().replace('Z', '')).toLocaleDateString()}{' '}
          to{' '}
          {new Date(
            props.coverage.expirationDate?.toString().replace('Z', '')
          ).toLocaleDateString()}
        </div>
      </li>
      {props.coverage.hasWaiverOfSubrogation && (
        <li className="flex gap-2 items-start">
          <CheckIcon className="text-sky-500 flex-[1_0_20px] mt-1 h-4 w-4" />
          <div className="flex-[1_0_100%]">Policy has waiver of subrogation</div>
        </li>
      )}
      {props.coverage.holderIsAdditionalInsured && (
        <li className="flex gap-2 items-start">
          <CheckIcon className="text-sky-500 flex-[1_0_20px] mt-1 h-4 w-4" />
          <div className="flex-[1_0_100%]">
            Policy lists certificate holder as an additional insured
          </div>
        </li>
      )}
      {props.coverage.type !== InsuranceType.WorkersCompensation &&
        props.coverage.limits.map((limit) => (
          <li key={limit.name} className="flex gap-2 items-start">
            <CheckIcon className="text-sky-500 flex-[1_0_20px] mt-1 h-4 w-4" />
            <div className="flex-[1_0_100%]">
              {limit.name} Limit:{' '}
              {new Intl.NumberFormat(undefined, {
                currency: 'USD',
                style: 'currency',
                maximumFractionDigits: 0
              }).format(limit.limit)}
            </div>
          </li>
        ))}
    </ul>
  </div>
);

const CertificateSection = (props: { certificate: CertificateDetails }) => (
  <CertificateSectionContainer>
    <CertificateLeftSection>
      {props.certificate.coverages.map((coverage) => (
        <CertificatePolicy key={coverage.type} coverage={coverage} />
      ))}
    </CertificateLeftSection>
    <CertificateRightSection>
      <CertificateEntity
        entity="Insured"
        name={props.certificate.insured.name}
        address={props.certificate.insured.address}
      />
      <CertificateEntity
        entity="Certificate Holder"
        name={props.certificate.certificateHolder.name}
        address={props.certificate.certificateHolder.address}
      />
      {props.certificate.descriptionOfOperations && (
        <CertificateEntity
          entity="Description of Operations"
          name=""
          address={props.certificate.descriptionOfOperations}
        />
      )}
    </CertificateRightSection>
  </CertificateSectionContainer>
);

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const Action = styled.button`
  border: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  padding: 8px 16px;
  border-radius: 30px;
  user-select: none;

  font-size: 0.7em;
  font-weight: 500;

  justify-content: center;

  color: #333333;
  background: #eaeaea;
  font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  svg {
    font-size: 1.2em;
  }

  &:hover {
    background: #e6e6e6;
  }

  &:active {
    background: #cccccc;
  }
`;

const MarketingBanner = () => (
  <div className="bg-primary-300/25 p-8 mt-12 rounded-xl">
    <h1 className="text-xl/6 font-medium">Get a competitive quote for your business insurance.</h1>
    <p className="text-base/5 mt-2 text-neutral-700">
      Oyster is the only all-in-one platform for all your insurance needs, from insurance for your
      business to insurance for your customers. Our team of experts can help you save up to 30% on
      insurance costs.
    </p>
    <Button
      color="sky"
      className="mt-4"
      href="https://www.withoyster.com/business-insurance"
      target="_blank"
    >
      Get a Quote
    </Button>
  </div>
);

export const Certificate = (props: { url: string; details: CertificateDetails }) => {
  const [copyClicked, setCopyClicked] = React.useState(false);

  React.useEffect(() => {
    if (!copyClicked) {
      return;
    }

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(window.location.href).catch(() => 0);
    }

    const timeout = setTimeout(() => {
      setCopyClicked(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copyClicked]);

  return (
    <div className="flex flex-col gap-4">
      <p className="text-pretty text-base text-neutral-700">
        This document was produced by {props.details.producer.name} to certify that the insured has
        been issued the listed policies, each with its respective limits and conditions.
      </p>
      <ActionsContainer>
        <Action onClick={() => setCopyClicked(true)}>
          {!copyClicked && (
            <>
              Copy Link <IoCopyOutline />
            </>
          )}
          {copyClicked && (
            <>
              Link Copied! <IoCheckmarkOutline />
            </>
          )}
        </Action>
        <a href={props.url || ''} target="_BLANK" style={{ textDecoration: 'none' }}>
          <Action>
            View Certificate <IoOpenOutline />
          </Action>
        </a>
      </ActionsContainer>
      <CertificateSection certificate={props.details} />
      <MarketingBanner />
    </div>
  );
};
