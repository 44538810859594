import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { Button as HeadlessButton } from '@headlessui/react';
import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions
} from '@oysterjs/uiv2/dialog';
import {
  ArrowTopRightOnSquareIcon,
  BellIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  ExclamationTriangleIcon as ExclamationTriangleIconOutline,
  LinkIcon
} from '@heroicons/react/24/outline';
import { ArrowRightIcon, CheckCircleIcon, CheckIcon, XCircleIcon } from '@heroicons/react/20/solid';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label
} from '@oysterjs/uiv2/fieldset';
import { Button } from '@oysterjs/uiv2/button';
import { Input } from '@oysterjs/uiv2/input';
import { Textarea } from '@oysterjs/uiv2/textarea';
import { Select } from '@oysterjs/uiv2/select';
import { Slideout } from '@oysterjs/uiv2/slideout';
import { RadioField } from '../commercial/form';
import {
  BusinessPolicyFieldsFragment,
  ComplianceActionStatus,
  PolicyConversationMessage,
  PolicyConversationMessageRole,
  PolicyState,
  RiskManagementDataFieldsFragment,
  RiskManagementMarkActionItemCompleteDocument,
  RiskManagementSetActionItemAssigneeDocument,
  RiskManagementSetActionItemDueDateDocument,
  useAddPolicyConversationMessageMutation,
  useGetPolicyConversationQuery,
  useGetRiskManagementQuery,
  useRiskManagementSetupMutation
} from '../../types/graphql';
import { Merchant, MerchantUser } from '@oysterjs/types';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { getDisplayInsuranceType } from '../../types/map';
import { PageNavigation } from '@oysterjs/ui/Page/navigation';

export const RiskPage = (props: {
  merchant: Merchant;
  businessPolicies: BusinessPolicyFieldsFragment[];
  riskManagement: RiskManagementDataFieldsFragment | null;
  enableRiskManagement: boolean;
}) => {
  const [showRiskManagementOnboarding, setShowRiskManagementOnboarding] = React.useState(false);

  const totalComplianceItems =
    props.riskManagement?.compliance?.reduce((acc, group) => acc + group.items.length, 0) || 1;
  const closedComplianceItems =
    props.riskManagement?.compliance?.reduce(
      (acc, group) =>
        acc +
        group.items.reduce(
          (acc, item) =>
            acc +
            (item.actions.length === 0 ||
            item.actions.find((action) => action.status === ComplianceActionStatus.Open)
              ? 0
              : 1),
          0
        ),
      0
    ) || 0;

  const compliancePercentage = closedComplianceItems / totalComplianceItems;

  const stats = [
    {
      id: 1,
      name: 'Action Items',
      stat: `${props.riskManagement?.actionItems.filter((item) => item.status === ComplianceActionStatus.Open).length} open`,
      icon: BellIcon,
      description: 'Total number of action items'
    },
    {
      id: 2,
      name: 'Compliance',
      stat: new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(compliancePercentage),
      icon: ChartBarIcon,
      description: 'Percentage of compliance'
    },
    {
      id: 3,
      name: 'Potential Risks',
      stat: '4',
      icon: ExclamationTriangleIconOutline,
      description: 'Total number of potential liabilities'
    }
  ];

  const actionItems = props.riskManagement?.actionItems || [];
  const complianceFactors: [string, number][] =
    props.riskManagement?.compliance
      ?.flatMap((group) => group.items)
      .map((item) => {
        const allActionItems = actionItems.filter((action) =>
          action.complianceItems.some((actionComplianceItem) => actionComplianceItem.id === item.id)
        );
        const completedItems = allActionItems.filter(
          (action) => action.status === ComplianceActionStatus.Closed
        );
        if (allActionItems.length > 0) {
          return [item.title, 1 - completedItems.length / allActionItems.length];
        }
        return [item.title, 0];
      }) || [];

  return (
    <>
      <PageTitle
        title="Risk Management"
        description="Manage and mitigate business risk with Oyster"
      />
      <OnboardRiskManagement
        open={showRiskManagementOnboarding}
        onClose={() => setShowRiskManagementOnboarding(false)}
      />

      <div className="space-y-8">
        {(!props.riskManagement || (!!props.riskManagement && !props.enableRiskManagement)) && (
          <div className="divide-y divide-neutral-200 rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Manage your business risk
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Monitor and actively mitigate operational risks and compliance requirements.
                  </p>
                </div>
                <div className="flex-shrink-0 mt-2 sm:mt-0">
                  <Button
                    type="button"
                    color="sky"
                    disabled={!!props.riskManagement && !props.enableRiskManagement}
                    onClick={() => setShowRiskManagementOnboarding(true)}
                  >
                    Get Started <ArrowRightIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
            {!!props.riskManagement && !props.enableRiskManagement && (
              <div className="px-4 py-5 sm:px-6">
                <p className="text-sm font-normal">
                  Your submission has been received. Our team is setting up your risk management
                  dashboard and will notify you when it's ready.
                </p>
              </div>
            )}
            {!props.riskManagement && (
              <div className="px-4 py-5 sm:px-6">
                <div className="flex flex-col-reverse sm:flex-row gap-4 sm:gap-8">
                  <div className="flex-shrink-0 w-full sm:w-1/2">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="h-auto w-auto rounded-lg bg-neutral-100 self-start"
                    >
                      <source
                        src="https://oyster-public.s3.us-west-2.amazonaws.com/app/partners/videos/risk_management.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-lg/6 mt-0 pt-0 font-medium">
                      Get started with Oyster Risk Management
                    </h3>
                    <p className="text-sm text-neutral-500">
                      Oyster Risk Management helps you monitor and actively mitigate operational
                      risks and compliance requirements.
                    </p>
                    <h4 className="text-base/6 font-medium font-body">How it works</h4>
                    <ul className="mt-2 p-0 list-none flex flex-col gap-1">
                      <li className="flex gap-2 items-start">
                        <CheckIcon
                          className="text-sky-500 flex-shrink-0 mt-1 h-4 w-4"
                          aria-hidden
                        />
                        <div>Choose your compliance requirements and frameworks</div>
                      </li>
                      <li className="flex gap-2 items-start">
                        <CheckIcon
                          className="text-sky-500 flex-shrink-0 mt-1 h-4 w-4"
                          aria-hidden
                        />
                        <div>View recommended compliance and action items</div>
                      </li>
                      <li className="flex gap-2 items-start">
                        <CheckIcon
                          className="text-sky-500 flex-shrink-0 mt-1 h-4 w-4"
                          aria-hidden
                        />
                        <div>Track your action completion and compliance progress</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!!props.riskManagement && props.enableRiskManagement && (
          <div className="divide-y divide-neutral-200 rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Manage your business risk
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Monitor and actively mitigate operational risks and compliance requirements.
                  </p>
                </div>
                <div className="flex-shrink-0 mt-2 sm:mt-0">
                  <Button href="/risk/manage" type="button" color="sky">
                    View Action Items <ArrowRightIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col md:flex-row gap-8">
                <div className="flex-1 @container">
                  <dl className="grid grid-cols-1 @sm:grid-cols-2 @xl:grid-cols-3 gap-4">
                    {stats.map((item) => (
                      <div key={item.id} className="relative overflow-hidden">
                        <dt>
                          <div className="absolute rounded-md bg-primary-200/50 p-3">
                            <item.icon aria-hidden="true" className="h-6 w-6 text-primary-600" />
                          </div>
                          <p className="ml-16 truncate text-sm font-medium text-neutral-500">
                            {item.name}
                          </p>
                        </dt>
                        <dd className="ml-16">
                          <p className="text-2xl font-semibold text-neutral-900">{item.stat}</p>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
                <div className="inline-flex">
                  <div className="grid grid-cols-8 gap-[1px] grid-rows-[auto]">
                    {complianceFactors.map(([, threshold], index) => {
                      const colorClasses: [number, string][] = [
                        [0.25, 'bg-green-300'],
                        [0.55, 'bg-green-200'],
                        [1.0, 'bg-green-100']
                        // [0.85, 'bg-yellow-100'],
                        // [0.95, 'bg-yellow-200'],
                        // [0.98, 'bg-red-100'],
                        // [1.0, 'bg-red-200']
                      ];
                      const colorClass =
                        colorClasses.find(([classThreshold]) => threshold <= classThreshold)?.[1] ||
                        'bg-neutral-300';

                      return (
                        <div
                          key={index}
                          className={`w-8 h-8 ${colorClass} relative group self-start`}
                        >
                          <div className="absolute invisible h-0 bottom-[100%] z-50 left-1/2 transform -translate-x-1/2 bg-neutral-700 text-neutral-50 text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 group-hover:visible group-hover:h-auto transition-opacity duration-150 whitespace-nowrap">
                            Risk factor {index + 1}: {Math.floor(threshold * 100)}% risk
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
              <div>
                <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                  Risk modeling
                </h3>
                <p className="text-sm text-neutral-500">
                  Ensure that your policies would still be adequate for your changing business
                </p>
              </div>
              <div className="flex-shrink-0 mt-2 sm:mt-0">
                <Button href="/risk/model" type="button" color="sky">
                  Start Modeling <ArrowRightIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Last Check-in</dt>
                <dd className="mt-1 text-sm text-neutral-900">March 15, 2024</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Annual Revenue</dt>
                <dd className="mt-1 text-sm text-neutral-900">$1,200,000</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Primary Operations</dt>
                <dd className="mt-1 text-sm text-neutral-900">Retail, E-Commerce</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Number of Employees</dt>
                <dd className="mt-1 text-sm text-neutral-900">25</dd>
              </div>
            </dl>
          </div>
        </div> */}

        {getExplorablePolicies(props.businessPolicies).length > 0 && (
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Explore your coverage
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Explore your policies and understand what you're covered for
                  </p>
                </div>
                <div className="flex-shrink-0 mt-2 sm:mt-0">
                  <Button href="/risk/explore" type="button" color="sky">
                    Launch Explorer <ArrowRightIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const OnboardRiskManagement = (props: { open: boolean; onClose: () => void }) => {
  const history = useHistory();
  const [riskManagementSetupMutation, { loading, error }] = useRiskManagementSetupMutation();

  const [form, setForm] = React.useState({
    description: '',
    changeDescription: '',
    complianceFrameworks: ''
  });
  const [validationErrors, setValidationErrors] = React.useState({
    description: '',
    changeDescription: '',
    complianceFrameworks: ''
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the form
    let hasErrors = false;
    setValidationErrors((prev) => {
      const next = { ...prev };
      if (!form.description.trim()) {
        next.description = 'This field is required.';
        hasErrors = true;
      }
      if (!form.changeDescription.trim()) {
        next.changeDescription = 'This field is required.';
        hasErrors = true;
      }
      return next;
    });
    if (hasErrors) {
      return;
    }

    riskManagementSetupMutation({
      variables: {
        description: form.description,
        changeDescription: form.changeDescription,
        complianceFrameworks: form.complianceFrameworks
      }
    }).then((res) => {
      if (res.data?.riskManagementSetup) {
        history.push('/risk');
        props.onClose();
      }
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} size="xl">
      <DialogTitle>Get Started with Risk Management</DialogTitle>
      <DialogDescription>
        Answer the following questions and our team will set up your risk and compliance management
        dashboard.
      </DialogDescription>
      <form onSubmit={handleSubmit}>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>Describe your current business, operations, and products.</Label>
                <Description>
                  Tell us about your business, products and services, who your customers are, where
                  you operate, etc.
                </Description>
                <Textarea
                  rows={3}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    setForm((prev) => ({ ...prev, description: val }));
                    setValidationErrors((prev) => ({ ...prev, description: '' }));
                  }}
                  invalid={!!validationErrors.description}
                />
                {!!validationErrors.description && (
                  <ErrorMessage>{validationErrors.description}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>
                  What are the biggest areas of change in your business operations you anticipate
                  over the next 12 months?
                </Label>
                <Description>
                  Examples: new product launch, new location, hiring, revenue growth, etc.
                </Description>
                <Textarea
                  rows={3}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    setForm((prev) => ({ ...prev, changeDescription: val }));
                    setValidationErrors((prev) => ({ ...prev, changeDescription: '' }));
                  }}
                  invalid={!!validationErrors.changeDescription}
                />
                {!!validationErrors.changeDescription && (
                  <ErrorMessage>{validationErrors.changeDescription}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Which compliance frameworks, if any, are relevant to your business?</Label>
                <Description>
                  Examples: GMP, SOC2, GDPR, PCI DSS, HIPAA, ISO 27001, NIST, etc.
                </Description>
                <Input
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    setForm((prev) => ({ ...prev, complianceFrameworks: val }));
                    setValidationErrors((prev) => ({ ...prev, complianceFrameworks: '' }));
                  }}
                  invalid={!!validationErrors.complianceFrameworks}
                />
                {!!validationErrors.complianceFrameworks && (
                  <ErrorMessage>{validationErrors.complianceFrameworks}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button type="button" color="white" onClick={props.onClose} disabled={loading}>
            Close
          </Button>
          <Button type="submit" color="sky" disabled={loading}>
            Submit
          </Button>
        </DialogActions>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </form>
    </Dialog>
  );
};

const getActionItemStatus = (action?: RiskManagementDataFieldsFragment['actionItems'][number]) => {
  let className = 'text-neutral-600 bg-neutral-50 ring-neutral-500/10';
  let title: string | undefined = action?.status;

  if (action?.status === ComplianceActionStatus.Closed) {
    className = 'text-green-700 bg-green-50 ring-green-600/20';
  }

  if (action?.status === ComplianceActionStatus.Open) {
    const dueDate = new Date(action.dueDate).toISOString().split('T')[0];
    const today = new Date().toISOString().split('T')[0];
    if (dueDate < today) {
      title = 'OVERDUE';
      className = 'text-yellow-800 bg-yellow-50 ring-yellow-600/20';
    }
  }

  return (
    <div
      className={clsx(
        className,
        'inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
      )}
    >
      {title}
    </div>
  );
};

const ActionItems = (props: RiskDashboardPageProps) => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const selectedActionItemId = location.pathname.split('/').pop();
  const selectedActionItem = props.riskManagement?.actionItems.find(
    (item) => item.id === selectedActionItemId
  );

  const updateUser = async (actionId: string, userId: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementSetActionItemAssigneeDocument,
        variables: {
          id: actionId,
          userId: userId
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
    }
  };

  const updateDueDate = async (actionId: string, dueDate: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementSetActionItemDueDateDocument,
        variables: {
          id: actionId,
          dueDate: new Date(dueDate).toISOString()
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
    }
  };

  const markComplete = async (actionId: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementMarkActionItemCompleteDocument,
        variables: {
          id: actionId
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
      setShowConfirmation(false);
      history.push('/risk/manage/actions');
    }
  };

  return (
    <>
      <Dialog
        open={showConfirmation}
        onClose={() => !(loading || props.loadingRiskManagement) && setShowConfirmation(false)}
      >
        <DialogTitle>Are you sure this action item is complete?</DialogTitle>
        <DialogDescription>
          This action item will be marked as complete and you won't be able to interact with it
          further. Only mark this as complete if you've verified that the action item has been
          completed.
        </DialogDescription>
        <DialogActions>
          <Button
            type="button"
            color="white"
            disabled={
              loading ||
              props.loadingRiskManagement ||
              selectedActionItem?.status === ComplianceActionStatus.Closed
            }
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            data-autofocus
            disabled={
              loading ||
              props.loadingRiskManagement ||
              selectedActionItem?.status === ComplianceActionStatus.Closed
            }
            onClick={() => markComplete(selectedActionItem?.id || '')}
            color="sky"
          >
            Mark as complete
          </Button>
        </DialogActions>
      </Dialog>

      <Slideout
        title="View action item"
        showing={!!selectedActionItem}
        onClose={() =>
          !(loading || props.loadingRiskManagement) && history.push('/risk/manage/actions')
        }
      >
        <h2 className="text-xl font-semibold mt-0 text-neutral-900">{selectedActionItem?.title}</h2>
        {!!selectedActionItem?.complianceItems?.length && (
          <div className="flex bg-primary-50/75 p-4 mt-3 rounded-md">
            <div className="flex-shrink-0">
              <LinkIcon className="h-5 w-5 text-primary-800" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm mb-2 font-medium text-primary-800">
                Linked to compliance items
              </p>
              {selectedActionItem?.complianceItems.map((item) => (
                <NavLink
                  key={item.id}
                  to={`/risk/manage/compliance/${item.id}`}
                  className="text-sm block mt-1 text-primary-600 hover:text-primary-900 cursor-pointer"
                >
                  {item.title}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                </NavLink>
              ))}
            </div>
          </div>
        )}

        <div className="rounded-md mt-4 border-b border-t border-neutral-100">
          <dl className="divide-y divide-neutral-100">
            <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                Status
              </dt>
              <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                {getActionItemStatus(selectedActionItem)}
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                Assignee
              </dt>
              <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                <Select
                  defaultValue={selectedActionItem?.assignee?.id}
                  disabled={
                    loading ||
                    props.loadingRiskManagement ||
                    selectedActionItem?.status === ComplianceActionStatus.Closed
                  }
                  onChange={(e) => updateUser(selectedActionItem?.id || '', e.currentTarget.value)}
                >
                  <option value="">Unassigned</option>
                  {props.users.map((user) => (
                    <option key={user.ID} value={user.ID}>
                      {user.FirstName} {user.LastName}
                    </option>
                  ))}
                </Select>
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                Due Date
              </dt>
              <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                <Input
                  type="date"
                  defaultValue={
                    selectedActionItem?.dueDate
                      ? new Date(selectedActionItem.dueDate).toISOString().split('T')[0]
                      : ''
                  }
                  disabled={
                    loading ||
                    props.loadingRiskManagement ||
                    selectedActionItem?.status === ComplianceActionStatus.Closed
                  }
                  onBlur={(e) => updateDueDate(selectedActionItem?.id || '', e.target.value)}
                />
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                Description
              </dt>
              <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-7 sm:mt-0">
                {selectedActionItem?.description}
              </dd>
            </div>
            <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                How to complete
              </dt>
              <dd className="mt-1 text-neutral-700 sm:col-span-7 sm:mt-0 flex flex-col gap-2">
                {selectedActionItem?.completionRequirements?.map((requirement) => (
                  <div key={requirement.title}>
                    <p className="text-sm leading-6">{requirement.title}</p>
                    <p className="text-xs text-neutral-500">{requirement.description}</p>
                  </div>
                ))}
              </dd>
            </div>
          </dl>
        </div>

        <Button
          type="button"
          color="sky"
          className="mt-4"
          disabled={
            loading ||
            props.loadingRiskManagement ||
            selectedActionItem?.status === ComplianceActionStatus.Closed
          }
          onClick={() => setShowConfirmation(true)}
        >
          Mark as complete
        </Button>
      </Slideout>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Action Items
              </h3>
              <p className="text-sm text-neutral-500">
                Recommended actions to take to ensure your business is compliant and protected.
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-neutral-200">
                <thead className="bg-neutral-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                    >
                      Action Item
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Assignee
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Due Date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {[...(props.riskManagement?.actionItems || [])]
                    .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
                    .map((action) => (
                      <tr key={action.id}>
                        <td className="py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                          <div className="text-sm font-medium text-neutral-900">{action.title}</div>
                          {/* <div className="text-xs mt-1 text-neutral-500"></div> */}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {getActionItemStatus(action)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm flex-inline items-center gap-2">
                          {action.assignee ? (
                            <div className="flex items-center gap-2">
                              <span className="rounded-full h-7 w-7 bg-primary-200/50 text-primary-600 flex items-center justify-center font-medium text-xs">
                                {action.assignee.name.split(' ')[0][0].toUpperCase()}
                                {action.assignee.name.split(' ')[1][0].toUpperCase()}
                              </span>
                              <span className="text-neutral-500">{action.assignee.name}</span>
                            </div>
                          ) : (
                            <span className="text-neutral-500 italic">Unassigned</span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {!isNaN(new Date(action.dueDate).getTime()) ? (
                            new Intl.DateTimeFormat('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                              timeZone: 'UTC'
                            }).format(new Date(action.dueDate))
                          ) : (
                            <span className="text-neutral-500 italic">No due date</span>
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <NavLink
                            to={`/risk/manage/actions/${action.id}`}
                            className="text-primary-600 hover:text-primary-900 cursor-pointer"
                          >
                            View
                            <span className="sr-only">, {action.title}</span>
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ComplianceItems = (props: RiskDashboardPageProps) => {
  const history = useHistory();
  const location = useLocation();

  const selectedComplianceItemId = location.pathname.split('/').pop();
  const selectedComplianceItem = props.riskManagement?.compliance
    ?.flatMap((group) => [...group.items])
    .find((item) => item.id === selectedComplianceItemId);

  return (
    <>
      <Slideout
        showing={!!selectedComplianceItem}
        onClose={() => history.push('/risk/manage/compliance')}
        title="View compliance item"
      >
        <h2 className="text-lg/6 font-semibold mt-0 text-neutral-900">
          {selectedComplianceItem?.title}
        </h2>
        <div className="mt-4 space-y-6">
          <div>
            <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">Description</h3>
            <p className="text-sm text-neutral-500">{selectedComplianceItem?.description}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">Standards</h3>
            <div className="text-sm text-neutral-500 flex flex-col">
              {selectedComplianceItem?.standards?.map((standard) => (
                <a
                  href={standard.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 hover:text-primary-900"
                >
                  {standard.title}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                </a>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
              Reference Materials
            </h3>
            <div className="text-sm text-neutral-500 flex flex-col">
              {selectedComplianceItem?.referenceMaterials?.map((material) => (
                <a
                  href={material.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 hover:text-primary-900"
                >
                  {material.title}
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                </a>
              ))}
            </div>
          </div>

          {!selectedComplianceItem?.actions.length && (
            <div>
              <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">Requirements</h3>
              <div className="text-sm text-neutral-500 flex flex-col">
                {selectedComplianceItem?.requirements?.map((requirement) => (
                  <span key={requirement.title}>{requirement.title}</span>
                ))}
              </div>
            </div>
          )}

          {!!selectedComplianceItem?.actions.length && (
            <div>
              <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">Progress</h3>
              <p className="text-sm text-neutral-500">
                {
                  selectedComplianceItem?.actions.filter(
                    (a) => a.status === ComplianceActionStatus.Closed
                  ).length
                }{' '}
                out of {selectedComplianceItem?.actions.length} linked action items completed.
              </p>
              <div className="h-2 mt-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-700">
                <div
                  className={`h-2 rounded-full bg-gradient-to-r to-primary-300 from-primary-600 bg-[size:200%_100%]`}
                  style={{
                    width: `${
                      (selectedComplianceItem?.actions.filter(
                        (a) => a.status === ComplianceActionStatus.Closed
                      ).length /
                        selectedComplianceItem?.actions.length) *
                      100
                    }%`
                  }}
                />
              </div>
            </div>
          )}

          {!!selectedComplianceItem?.actions.length && (
            <div>
              <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                Linked Action Items
              </h3>
              <ul role="list" className="divide-y divide-gray-100">
                {selectedComplianceItem?.actions.map((action) => (
                  <li key={action.id} className="flex justify-between gap-x-6 py-3">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-2">
                        {action.status === ComplianceActionStatus.Closed ? (
                          <CheckCircleIcon className="h-4 w-4 text-primary-500" />
                        ) : (
                          <div className="h-4 w-4 inline rounded-full border border-neutral-300" />
                        )}
                        <NavLink
                          to={`/risk/manage/actions/${action.id}`}
                          className="text-sm leading-6 text-gray-900 hover:text-primary-900 cursor-pointer"
                        >
                          {action.title}
                        </NavLink>
                      </div>
                      <div className="mt-1 ml-6 flex items-center gap-x-2 leading-5 text-gray-500">
                        <p className="whitespace-nowrap text-xs">
                          Due on{' '}
                          <time dateTime={new Date(action.dueDate).toISOString()}>
                            {new Intl.DateTimeFormat('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                              timeZone: 'UTC'
                            }).format(new Date(action.dueDate))}
                          </time>
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        {!!action.assignee && (
                          <p className="truncate text-xs">Assigned to {action.assignee?.name}</p>
                        )}
                        {!action.assignee && <p className="truncate text-xs">Unassigned</p>}
                      </div>
                    </div>
                    <div className="flex items-start gap-x-2">
                      <NavLink
                        to={`/risk/manage/actions/${action.id}`}
                        className="text-primary-600 hover:text-primary-900 cursor-pointer"
                      >
                        <EyeIcon className="h-5 w-5 mt-1" />
                        <span className="sr-only">View action item</span>
                      </NavLink>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Slideout>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Compliance Requirements
              </h3>
              <p className="text-sm text-neutral-500">
                Based on your business type and insurance policies, here are the compliance
                requirements you should consider.
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full">
                <thead>
                  <th
                    scope="col"
                    className="pl-4 pr-3 py-0 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                  >
                    <span className="sr-only">Completed</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-0 text-left text-sm font-semibold text-neutral-900"
                  >
                    <span className="sr-only">Compliance Requirement</span>
                  </th>
                  <th scope="col" className="relative pl-3 py-0 pr-4 sm:pr-6 lg:pr-8">
                    <span className="sr-only">View</span>
                  </th>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {props.riskManagement?.compliance?.map((category) => (
                    <React.Fragment key={category.slug}>
                      <tr className="border-t first:border-t-0 border-neutral-200">
                        <th className="bg-neutral-50" />
                        <th
                          colSpan={2}
                          scope="colgroup"
                          className="bg-neutral-50 py-3.5 pl-3 text-left text-sm font-semibold text-neutral-900"
                        >
                          {category.title}
                        </th>
                      </tr>
                      {category.items.map((item) => (
                        <tr key={item.id}>
                          <td className="py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                            {item.actions.length > 0 &&
                              !item.actions.find(
                                (a) => a.status === ComplianceActionStatus.Open
                              ) && <CheckCircleIcon className="h-5 w-5 text-primary-500" />}
                          </td>
                          <td className="px-3 py-4 text-sm">{item.title}</td>
                          <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <NavLink
                              to={`/risk/manage/compliance/${item.id}`}
                              className="text-primary-600 hover:text-primary-900 cursor-pointer"
                            >
                              View<span className="sr-only">, {item.title}</span>
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface RiskDashboardPageProps {
  users: MerchantUser[];
  riskManagement: RiskManagementDataFieldsFragment | null;
  loadingRiskManagement: boolean;
  refetchRiskManagement: () => Promise<unknown>;
}

export const RiskDashboardPage = (props: {
  merchant: Merchant;
  users: MerchantUser[];
  businessPolicies: BusinessPolicyFieldsFragment[];
  riskManagement: RiskManagementDataFieldsFragment | null;
}) => {
  const { data, loading, refetch } = useGetRiskManagementQuery();

  return (
    <PageTitle
      title="Risk Dashboard"
      description="View your compliance requirements and risk-reduction action items."
    >
      <PageNavigation
        redirect={{ '/': '/actions' }}
        routes={[
          {
            path: '/actions',
            title: 'Action Items',
            render: () => (
              <ActionItems
                users={props.users}
                riskManagement={data?.riskManagement || props.riskManagement}
                loadingRiskManagement={loading}
                refetchRiskManagement={refetch}
              />
            ),
            count:
              props.riskManagement?.actionItems?.filter(
                (item) => item.status === ComplianceActionStatus.Open
              )?.length || 0
          },
          {
            path: '/compliance',
            title: 'Compliance',
            render: () => (
              <ComplianceItems
                users={props.users}
                riskManagement={data?.riskManagement || props.riskManagement}
                loadingRiskManagement={loading}
                refetchRiskManagement={refetch}
              />
            ),
            count:
              props.riskManagement?.compliance?.reduce(
                (acc, group) => acc + group.items.length,
                0
              ) || 0
          }
        ]}
      />
    </PageTitle>
  );
};

const ModelRiskFieldNote = (props: {
  original: string;
  changeAmount: string;
  positive: boolean;
}) => (
  <div className="mt-1 text-xs">
    {props.positive ? (
      <>
        <span className="font-medium text-green-600">{props.changeAmount} increase</span>{' '}
        <span className="text-neutral-500">from {props.original}</span>
      </>
    ) : (
      <>
        <span className="font-medium text-red-600">{props.changeAmount} decrease</span>{' '}
        <span className="text-neutral-500">from {props.original}</span>
      </>
    )}
  </div>
);

export const ModelRiskPage = () => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [showRiskModel, setShowRiskModel] = React.useState(false);

  const [form, setForm] = React.useState({
    annualRevenue: '120000',
    totalPayroll: '60000',
    primaryOperations: 'Retail, E-Commerce',
    productTypes: 'Bikes, Apparel, Accessories',
    additionalOperations: 'Bike Rentals, Bike Repairs, Bike Sales'
  });

  return (
    <>
      <PageTitle
        title="Business Risk Modeling"
        description="Use Oyster to model how your insurance and risk requirements change as your business grows. Fill out the form to get updated recommendations for your business."
      />
      {showRiskModel && (
        <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
              <div>
                <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                  Your risk results
                </h3>
                <p className="text-sm text-neutral-500">
                  Based on your information, here are our risk and insurance recommendations.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 bg-green-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-green-800">Cyber Insurance</h5>
                    <p className="text-xs text-green-700">
                      Coverage is adequate. No changes needed.
                    </p>
                  </div>
                  <CheckCircleIcon className="h-6 w-6 text-green-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-yellow-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-yellow-800">General Liability</h5>
                    <p className="text-xs text-yellow-700">
                      Risk exposure exceeds policy limits. We recommend increasing your coverage.
                    </p>
                    <p className="text-xs font-medium text-yellow-700">
                      Estimated additional cost: $400/year
                    </p>
                  </div>
                  <XCircleIcon className="h-6 w-6 text-yellow-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-yellow-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-yellow-800">Workers Compensation</h5>
                    <p className="text-xs text-yellow-700">
                      Risk exposure exceeds policy limits. We recommend increasing your coverage.
                    </p>
                    <p className="text-xs font-medium text-yellow-700">
                      Estimated additional cost: $1,600/year
                    </p>
                  </div>
                  <XCircleIcon className="h-6 w-6 text-yellow-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-blue-800">Rental Insurance</h5>
                    <p className="text-xs text-blue-700">
                      Rentals are not covered by your existing insurance. We recommended a rental
                      insurance policy.
                    </p>
                    <p className="text-xs font-medium text-blue-700">
                      Estimated cost: $500 - $1,500/year
                    </p>
                  </div>
                  <CursorArrowRaysIcon className="h-6 w-6 text-blue-500" />
                </div>
              </div>

              <div className="flex gap-4 mt-6">
                <Button type="button" color="white" onClick={() => setShowRiskModel(false)}>
                  Go Back
                </Button>
                <Button type="button" color="sky">
                  Contact your agent
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showRiskModel && (
        <form
          className="space-y-8"
          ref={ref}
          onSubmit={(e) => {
            e.preventDefault();
            setShowRiskModel(true);
          }}
        >
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Size
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Update business size information to see how your insurance and risk requirements
                    change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <Field>
                      <Label>Annual Revenue</Label>
                      <Description>
                        The total annual revenue generated by your business.
                      </Description>
                      <Input
                        name="annualRevenue"
                        type="number"
                        value={form.annualRevenue}
                        onChange={(e) => setForm({ ...form, annualRevenue: e.target.value })}
                      />
                      {!isNaN(parseFloat(form.annualRevenue)) &&
                        parseFloat(form.annualRevenue) !== 120000 && (
                          <ModelRiskFieldNote
                            original="$120,000"
                            changeAmount={`${new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: 'currency',
                              currency: 'USD'
                            }).format(Math.abs(parseFloat(form.annualRevenue) - 120000))}`}
                            positive={parseFloat(form.annualRevenue) > 120000}
                          />
                        )}
                    </Field>
                    <Field>
                      <Label>Total Payroll</Label>
                      <Description>
                        The total annual employee payroll for your business.
                      </Description>
                      <Input
                        type="number"
                        value={form.totalPayroll}
                        onChange={(e) => setForm({ ...form, totalPayroll: e.target.value })}
                      />
                      {!isNaN(parseFloat(form.totalPayroll)) &&
                        parseFloat(form.totalPayroll) !== 60000 && (
                          <ModelRiskFieldNote
                            original="$60,000"
                            changeAmount={`${new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: 'currency',
                              currency: 'USD'
                            }).format(Math.abs(parseFloat(form.totalPayroll) - 60000))}`}
                            positive={parseFloat(form.totalPayroll) > 60000}
                          />
                        )}
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Operations
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Update business operations information to see how your insurance and risk
                    requirements change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <Field>
                      <Label>Primary Operations</Label>
                      <Description>The primary operations of your business.</Description>
                      <Input
                        type="text"
                        value={form.primaryOperations}
                        onChange={(e) => setForm({ ...form, primaryOperations: e.target.value })}
                      />
                    </Field>
                    <Field>
                      <Label>Product Types</Label>
                      <Description>The types of products you sell.</Description>
                      <Input
                        type="text"
                        value={form.productTypes}
                        onChange={(e) => setForm({ ...form, productTypes: e.target.value })}
                      />
                    </Field>
                  </div>
                  <Field>
                    <Label>Additional Operations</Label>
                    <Description>Describe any additional operations of your business.</Description>
                    <Textarea
                      rows={4}
                      resizable={false}
                      value={form.additionalOperations}
                      onChange={(e) => setForm({ ...form, additionalOperations: e.target.value })}
                    />
                  </Field>
                </FieldGroup>
              </Fieldset>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Risk Factors
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Answer a few questions about your business' risk factors to see how your
                    insurance and risk requirements change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <RadioField
                    onChange={() => {}}
                    label="Do you manufacture using your own brand?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                  <RadioField
                    onChange={() => {}}
                    label="Will bike rentals exceed 20% of your annual sales?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                  <RadioField
                    onChange={() => {}}
                    label="Do you have a legally-reviewed rental liability release form?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                </FieldGroup>
              </Fieldset>
            </div>
          </div>
          <Button color="sky" type="submit">
            Model my risk
          </Button>
        </form>
      )}
    </>
  );
};

export const ExploreRiskPage = (props: { businessPolicies: BusinessPolicyFieldsFragment[] }) => {
  const explorablePolicies = getExplorablePolicies(props.businessPolicies);
  if (!explorablePolicies.length) {
    return null;
  }

  const [selectedPolicy, setSelectedPolicy] = React.useState<BusinessPolicyFieldsFragment | null>(
    explorablePolicies[0] || null
  );
  const [showPolicyQuotes, setShowPolicyQuotes] = React.useState<Record<string, boolean>>({});

  if (!selectedPolicy) {
    return (
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-neutral-400"
        >
          <path
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-neutral-900">No policies</h3>
        <p className="mt-1 text-sm text-neutral-500">
          You don't have any policies to explore. Get started by applying for one.
        </p>
        <div className="mt-6 flex gap-2 items-center justify-center">
          <Button type="button" color="white">
            Go back
          </Button>
          <Button type="button" color="sky">
            Apply for a policy
          </Button>
        </div>
      </div>
    );
  }

  const policyStats = [
    {
      name: 'Premium',
      value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        selectedPolicy.pricing.premium || 0
      )
    },
    // {
    //   name: 'Deductible',
    //   value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    //     selectedPolicy.details?.deductible || 0
    //   )
    // },
    {
      name: 'Policy Number',
      value: selectedPolicy.policyNumber || selectedPolicy.details?.policyNumber || 'N/A'
    },
    {
      name: 'Expiration',
      value: new Date(selectedPolicy.expiresAt || '').toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    }
  ];

  const policyLimits = [
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.cyberAggregateLimit },
    { name: 'retention', limit: selectedPolicy.details?.limits?.cyberRetentionLimit },
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.glAggregateLimit },
    {
      name: 'medical per occurrence',
      limit: selectedPolicy.details?.limits?.glMedicalPerOccurrenceLimit
    },
    {
      name: 'medical per person',
      limit: selectedPolicy.details?.limits?.glMedicalPerPersonLimit
    },
    { name: 'per occurrence', limit: selectedPolicy.details?.limits?.glPerOccurrenceLimit },
    {
      name: 'personal and advertising injury',
      limit: selectedPolicy.details?.limits?.glPersonalAndAdvertisingInjuryLimit
    },
    {
      name: 'products and completed operations',
      limit: selectedPolicy.details?.limits?.glProductsAndCompletedOperationsLimit
    },
    {
      name: 'rented premises damage',
      limit: selectedPolicy.details?.limits?.glRentedPremisesDamageLimit
    },
    { name: 'building', limit: selectedPolicy.details?.limits?.propertyBuildingLimit },
    { name: 'contents', limit: selectedPolicy.details?.limits?.propertyContentsLimit },
    { name: 'per accident', limit: selectedPolicy.details?.limits?.wcPerAccidentLimit },
    {
      name: 'per disease (employee)',
      limit: selectedPolicy.details?.limits?.wcPerDiseaseEmployeeLimit
    },
    {
      name: 'per disease (policy)',
      limit: selectedPolicy.details?.limits?.wcPerDiseasePolicyLimit
    },
    ...(selectedPolicy.details?.extraLimits || []),
    ...(selectedPolicy.details?.locations?.flatMap((location) => [
      {
        name: `building (${location.address.line1})`,
        limit: location.limits?.propertyBuildingLimit
      },
      {
        name: `contents (${location.address.line1})`,
        limit: location.limits?.propertyContentsLimit
      }
    ]) || [])
  ].filter((limit) => !!limit?.limit);

  return (
    <>
      <PageTitle
        title="Explore your coverage"
        description="Explore your policies and understand what you're covered for. Make sure you speak with an agent before acting on any information here."
      />
      <form className="sm:max-w-md" onSubmit={(e) => e.preventDefault()}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Choose a policy</Label>
              <Description>Select the policy you want to explore.</Description>
              <Select
                value={selectedPolicy.id}
                onChange={(e) => {
                  setSelectedPolicy(
                    explorablePolicies.find((p) => p.id === e.currentTarget.value) || null
                  );
                }}
              >
                {explorablePolicies.map((policy) => (
                  <option key={policy.id} value={policy.id}>
                    {getDisplayInsuranceType(policy.type)} (
                    {policy.policyNumber || policy.details?.policyNumber || 'N/A'})
                  </option>
                ))}
              </Select>
            </Field>
          </FieldGroup>
        </Fieldset>
      </form>
      <div className="mt-8 w-full grow xl:flex">
        <div className="flex-1 space-y-8 pb-8 w-full xl:pb-0 xl:pr-4 xl:flex-1">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Policy overview
                  </h3>
                  <p className="text-sm text-neutral-500">Basic details about your policy.</p>
                </div>
              </div>
            </div>
            <div className="@container">
              <dl className="px-4 py-5 sm:px-6 mx-auto grid grid-cols-1 gap-8 @sm:grid-cols-2 @lg:grid-cols-3">
                {policyStats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between bg-white"
                  >
                    <dt className="text-sm font-medium text-neutral-500">{stat.name}</dt>
                    <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-neutral-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              <dl className="divide-y divide-neutral-100 border-t border-neutral-100 pb-2">
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Insured Entity</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.insured?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.insured?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.insured?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.insured?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.insured?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Carrier</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.carrier?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.carrier?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.carrier?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.carrier?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.carrier?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Policy Summary</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {selectedPolicy.details?.summary || 'Unknown'}
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Limits</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {policyLimits.map((limit) => (
                      <div key={limit.name}>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          currency: 'USD'
                        }).format(limit.limit || 0)}{' '}
                        {limit.name}
                      </div>
                    ))}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's covered
                  </h3>
                  <p className="text-sm text-neutral-500">Coverage for your business.</p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.coverageDescriptions?.map((coverage) => (
                    <div key={coverage.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <CheckCircleIcon className="h-4 w-4 text-emerald-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{coverage.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {coverage.description}
                      </dd>
                      {coverage.policyQuotes.length > 0 && !showPolicyQuotes[coverage.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [coverage.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {coverage.policyQuotes.length > 0 && showPolicyQuotes[coverage.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {coverage.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The coverage descriptions provided here may not be complete, and may have
                  important exclusions or limitations. For a full understanding of your coverage,
                  please read your policy documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's not covered
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Exclusions and other limitations on your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.exclusionDescriptions?.map((exclusion) => (
                    <div key={exclusion.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <XCircleIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{exclusion.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {exclusion.description}
                      </dd>
                      {exclusion.policyQuotes.length > 0 && !showPolicyQuotes[exclusion.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [exclusion.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {exclusion.policyQuotes.length > 0 && showPolicyQuotes[exclusion.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {exclusion.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The exclusions provided here may not be complete, and may have important caveats
                  or conditions. For a full understanding of your policy, please read your policy
                  documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="shrink-0 border-neutral-200 w-full space-y-8 xl:pl-4 xl:w-[25rem]">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Ask a question
                  </h3>
                  <p className="text-sm text-neutral-500">
                    We'll find related information in your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <PolicyChat policyId={selectedPolicy.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PolicyChat = (props: { policyId: string }) => {
  // Load the initial conversation messages
  const {
    data: initialData,
    loading: initialLoading,
    refetch: refetchInitialData
  } = useGetPolicyConversationQuery({
    variables: { policyId: props.policyId }
  });

  // Send a message to the policy chat
  const [
    sendMessage,
    { data: sendMessageData, loading: sendMessageLoading, error: sendMessageError }
  ] = useAddPolicyConversationMessageMutation();

  // State for the chat
  const [editorText, setEditorText] = React.useState('');
  const [messages, setMessages] = React.useState<PolicyConversationMessage[]>([]);
  const [stagedMessage, setStagedMessage] = React.useState('');
  const [showAttachments, setShowAttachments] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    refetchInitialData({ policyId: props.policyId });
  }, [props.policyId]);

  React.useEffect(() => {
    if (initialData) {
      setMessages(initialData.policyConversation);
    }
  }, [initialData]);

  React.useEffect(() => {
    if (sendMessageData) {
      setMessages(sendMessageData.riskManagementAddPolicyConversationMessage);
    }
  }, [sendMessageData?.riskManagementAddPolicyConversationMessage]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEditorText('');
    setStagedMessage(editorText);
    await sendMessage({
      variables: { policyId: props.policyId, message: editorText }
    });
    setStagedMessage('');
  };

  if (initialLoading) {
    return (
      <div className="space-y-4 animate-pulse">
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="rounded-lg p-3 w-full max-w-[75%] bg-primary-100">
            <div className="h-3 w-3/4 bg-primary-300 rounded-full" />
            <div className="h-3 w-1/2 bg-primary-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-2/3 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex">
          <div className="rounded-lg w-full h-32 border border-neutral-300" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-4">
          <div className="flex justify-start">
            <div className="rounded-lg p-3 bg-neutral-100 text-neutral-900 max-w-[75%] xl:max-w-full">
              <pre className="font-body text-sm whitespace-pre-wrap">
                Hello! I'm here to look up information in your policy documents. What would you like
                to know?
              </pre>
            </div>
          </div>
          {messages.map((message) => (
            <div
              key={message.id}
              className={clsx(
                'flex',
                message.role === PolicyConversationMessageRole.User
                  ? 'justify-end'
                  : 'justify-start'
              )}
            >
              <div
                className={clsx(
                  'rounded-lg p-3 flex flex-col max-w-[75%] xl:max-w-full',
                  message.role === PolicyConversationMessageRole.User
                    ? 'bg-sky-500 text-white'
                    : 'bg-neutral-100 text-neutral-900'
                )}
              >
                <pre className="font-body text-sm whitespace-pre-wrap">{message.content}</pre>
                {message.attachments.length > 0 && !showAttachments[message.id] && (
                  <HeadlessButton
                    onClick={() =>
                      setShowAttachments({
                        ...showAttachments,
                        [message.id]: true
                      })
                    }
                    className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 mt-2"
                  >
                    Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                  </HeadlessButton>
                )}
                {message.attachments.length > 0 && showAttachments[message.id] && (
                  <div className="flex flex-col gap-2 mt-4">
                    {message.attachments?.map((attachment) => (
                      <div
                        key={attachment.content}
                        className="text-xs border-l-4 border-neutral-200 px-2 py-0.5"
                      >
                        {attachment.content}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {sendMessageLoading && (
            <>
              <div className="flex justify-end">
                <div className="rounded-lg p-3 bg-sky-500 text-white">
                  <pre className="font-body text-sm whitespace-pre-wrap">{stagedMessage}</pre>
                </div>
              </div>
              <AIChatProgress />
            </>
          )}
          <div className="mt-4 text-center">
            <p className="text-xs text-neutral-400">
              Please note: This assistant will attempt to find information in your policy. However,
              it is not a licensed agent. Always check your policy documents or schedule a call with
              an Oyster agent for the most accurate information.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <form onSubmit={handleSubmit} className="relative">
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label className="sr-only">Ask a question or followup</Label>
                <Textarea
                  rows={4}
                  placeholder="Ask a question or followup..."
                  resizable={false}
                  disabled={sendMessageLoading}
                  value={editorText}
                  onChange={(e) => setEditorText(e.target.value)}
                />
                {sendMessageError && <ErrorMessage>{sendMessageError.message}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>

          <Button
            type="submit"
            color="sky"
            className="mt-4 justify-end"
            disabled={sendMessageLoading}
          >
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
};

const AIChatProgress = () => {
  // Build a two-minute animation to keep the user engaged while the quote loads
  const steps = [
    { title: 'Loading policy documents...', maxDuration: 2000, maxWidth: 10 },
    { title: 'Reading carefully...', maxDuration: 6000, maxWidth: 45 },
    { title: 'Thinking...', maxDuration: 5000, maxWidth: 80 },
    { title: 'Thinking...', maxDuration: 8000, maxWidth: 90 },
    { title: 'Thinking...', maxDuration: 13000, maxWidth: 100 }
  ];

  const [stepIndex, setStepIndex] = React.useState(0);
  React.useEffect(() => {
    if (stepIndex === steps.length) {
      return;
    }

    const timeout = setTimeout(() => {
      setStepIndex((prev) => (prev >= steps.length - 1 ? prev : prev + 1));
    }, steps[stepIndex].maxDuration);

    return () => clearTimeout(timeout);
  }, [stepIndex]);

  return (
    <div className="flex justify-start">
      <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
        <div className="h-3 w-3/4 bg-neutral-300 rounded-full animate-pulse" />
        <div className="h-3 w-2/3 bg-neutral-300 rounded-full mt-2 animate-pulse" />
        <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2 animate-pulse" />

        <div className="mt-4 text-xs font-medium text-primary-600 dark:text-primary-400">
          {steps[stepIndex].title}
        </div>
        <div className="mt-1">
          <div className="h-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-700">
            <div
              className={`h-2 rounded-full bg-gradient-to-r to-primary-300 from-primary-600 bg-[size:200%_100%] animate-progress-bar transition-all ease-in-out duration-1000`}
              style={{ width: `${steps[stepIndex].maxWidth}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getExplorablePolicies = (policies: BusinessPolicyFieldsFragment[]) =>
  policies.filter(
    (policy) => policy.state === PolicyState.Inforce && !!policy.details?.carrier?.address.line1
  );
